import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import Image from 'components/generic/Image'
import Button from 'components/generic/Button'
import Tag from 'components/generic/Tag'
import theme from 'material-ui-theme'
import Rating from './rating'
import * as AuthSelectors from 'redux/auth/selectors'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { EventHelpers } from '@hackjunction/shared'

const useStyles = makeStyles(theme => ({
    wrapper: {
        flex: 1,
        background: 'white',
        // borderRadius: '12px',
        overflow: 'hidden',
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
        textAlign: 'left',
    },
    image: {
        height: 'auto',
        width: '100%',
        objectFit: 'fill',
        background: 'black',
        borderRadius: '8px',
        aspectRatio: '16/9',
    },
    placeholderImage: {
        height: 'auto',
        width: '100%',
        objectFit: 'fill',
        background: 'black',
        borderRadius: '8px',
        aspectRatio: '16/9',
    },
    content: {
        textAlign: 'center',
    },
    category: {
        textTransform: 'capitalize',
        background: '#CDF4FE',
        borderRadius: '24px',
        padding: '8px 16px',
        lineHeight: 'normal',
        color: '#0278D5',
    },
    rating: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    projectCard: {
        cursor: 'pointer',
    },
    label: ({ labelBackground }) => ({
        background: theme.palette[labelBackground].main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        padding: theme.spacing(1),
        fontWeight: 'bold',
        textTransform: 'uppercase',
    }),
}))

const ProjectsGridItem = ({
    project,
    event,
    showTableLocation,
    onClickMore,
    label,
    labelBackground = 'primary',
    score = null,
    message = null,
    showTags = false,
}) => {
    const user = useSelector(AuthSelectors.idTokenData)
    const classes = useStyles({ labelBackground })
    const previewImage =
        project.images.length > 0 ? project.images[0].publicId : ''

    const statusTag = status => {
        switch (status) {
            case 'final':
                return (
                    <Tag
                        label="Final"
                        color={theme.palette.theme_turquoise.main}
                    />
                )
            case 'draft':
                return (
                    <Tag
                        label="Draft"
                        color={theme.palette.theme_lightgray.main}
                    />
                )
            default:
                return null
        }
    }

    console.log(project)
    return (
        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
            <Box className={classes.wrapper}>
                {label && (
                    <Typography className={classes.label}>{label}</Typography>
                )}
                <Box position="relative">
                    <a onClick={onClickMore} className={classes.projectCard}>
                        <Image
                            className={
                                previewImage
                                    ? classes.image
                                    : classes.placeholderImage
                            }
                            publicId={
                                previewImage
                                    ? previewImage
                                    : event?.coverImage?.publicId
                                    ? event?.coverImage.publicId
                                    : event?.logo?.publicId
                                    ? event?.logo.publicId
                                    : false
                            }
                            defaultImage={require('assets/images/default_cover_image.png')}
                            transformation={{
                                width: 600,
                            }}
                        />
                    </a>
                    <Box className={classes.rating}>
                        <Rating
                            project={project}
                            user={user}
                            slug={event.slug}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1" className={classes.category}>
                        {project.track}
                    </Typography>
                    <Box textAlign="start">
                        {project.globalScore !== 0 &&
                            EventHelpers.isEventOver(
                                event,
                                moment,
                                // userProfile.location,
                            ) && (
                                <Typography
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                    variant="body1"
                                >
                                    Global Score:{' '}
                                    {parseFloat(project.globalScore).toFixed(2)}
                                </Typography>
                            )}
                        {project.score !== null ? (
                            <Typography
                                style={{
                                    textTransform: 'uppercase',
                                }}
                                variant="body1"
                            >
                                Regional Score:{' '}
                                {parseFloat(project.score).toFixed(2)}
                            </Typography>
                        ) : (
                            score !== null && (
                                <Typography
                                    style={{ textTransform: 'uppercase' }}
                                    variant="body1"
                                >
                                    Regional Score: {parseFloat(score).toFixed(2)}
                                </Typography>
                            )
                        )}

                    </Box>                    
                </Box>
                <Box className={classes.content}>
                    <Typography variant="h6">{project.name}</Typography>
                    <Typography variant="body1">{project.punchline}</Typography>
                    {onClickMore && (
                        <Button
                            onClick={onClickMore}
                            variant="outlinedNew"
                            color="theme_blue"
                        >
                            See more
                        </Button>
                    )}
                </Box>
                {/* <Box pb={2} pl={2} pr={2}>
                        {showTags &&
                            typeof project.status !== 'undefined' &&
                            statusTag(project.status)}
                    </Box> */}
                {showTableLocation && project.location && (
                    <Box pb={2} pl={2} pr={2}>
                        <Typography
                            style={{ fontWeight: 'bold' }}
                            variant="body1"
                        >
                            Table Location
                        </Typography>
                        <Typography variant="body1">
                            {project.location}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Grid>
    )
}

export default ProjectsGridItem
