import React, { useEffect, useState } from 'react'

import { find, filter } from 'lodash-es'
import { Box, Typography, Button, Tooltip, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import Image from 'components/generic/Image'
import Container from 'components/generic/Container'
import Markdown from 'components/generic/Markdown'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import config from 'constants/config'
import { Helmet } from 'react-helmet'
import { EventHelpers } from '@hackjunction/shared'
import ReactPlayer from 'react-player'
import moment from 'moment-timezone'
import ProjectTeam from './ProjectTeam'
import Pagination from './Pagination'
import theme from 'material-ui-theme'
import Tag from 'components/generic/Tag'
import Scoring from './Scoring'
import { useDispatch, useSelector } from 'react-redux'
import * as AuthSelectors from 'redux/auth/selectors'
import * as UserSelectors from 'redux/user/selectors'
import RadioButtons from 'components/radioButtons'
import * as DashboardActions from 'redux/dashboard/actions'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import Dashboard from '@material-ui/icons/Dashboard'
import AdditionalFiles from './AdditionalFiles'
import Comments from 'components/Comment'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        position: 'relative',
        maxWidth: '1920px',
        margin: '0 auto',
    },
    top: {
        width: '100%',
        // paddingTop: '50%',
        position: 'relative',
        overflow: 'hidden',
        background: 'black',
        // [theme.breakpoints.up('lg')]: {
        //     paddingTop: theme.breakpoints.values.lg / 2,
        // },
    },
    image: {
        // position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        background: 'black',
        aspectRatio: '16/9',
        objectFit: 'contain',
    },
    placeholderTop: {
        background: 'black',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:hover': {
            opacity: 0.6,
        },
    },
    placeholderImage: {
        width: '100%',
        maxWidth: '600px',
    },
    content: {
        marginTop: theme.spacing(5),
    },
    backButtonWrapper: {
        background: 'black',
        position: 'absolute',
        top: 0,
        left: 0,
    },
    sectionTitle: {
        textTransform: 'uppercase',
    },
    pagination: {
        position: 'absolute',
        top: 0,
        right: 0,
        background: 'rgba(0,0,0,0.4)',
        zIndex: 100,
        color: 'white',
        padding: theme.spacing(2),
    },
    paginationText: {
        color: 'white',
    },
    doneTitle: {
        color: 'black',
        textAlign: 'center',
    },
    socialIcon: {
        color: 'black',
        width: 'auto',
        margin: '0.10rem',
        cursor: 'pointer',
    },
    playerWrapper: {
        position: 'relative',
        // height: '360px',
    },
    reactPlayer: {
        // position: 'absolute',
        top: 0,
        left: 0,
    },
}))
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: '90vh',
    overflowY: 'auto',
}

const ProjectDetail = ({
    project,
    event,
    onBack,
    showTableLocation,
    showFullTeam,
    // handleScoring,
    scores,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [index, setIndex] = useState(0)
    const [pause, setPause] = useState(true)
    const [score1, setScore1] = useState(0)
    const [score2, setScore2] = useState(0)
    const [score3, setScore3] = useState(0)
    const [score4, setScore4] = useState(0)
    const [score5, setScore5] = useState(0)
    const [score6, setScore6] = useState(0)
    const [score, setScore] = useState(0)
    const [feedback, setFeedback] = useState('')
    const allScores = useSelector(DashboardSelectors.scores)
    const comments = useSelector(DashboardSelectors.comments)
    const userProfile = useSelector(UserSelectors.userProfile)
    const [open, setOpen] = React.useState(false)
    const user = useSelector(AuthSelectors.idTokenData)
    const [scored, setScored] = useState(0)

    // console.log('main', user, userProfile, project)
    const handleModalOpen = () => setOpen(true)
    const handleModalClose = () => setOpen(false)
    const handleScoring = (projectId, score, feedback) => {
        console.log(score)
        dispatch(DashboardActions.giveScore(projectId, score, event.slug))
        dispatch(DashboardActions.giveFeedback(projectId, feedback, event.slug))
        dispatch(DashboardActions.getProjectsByRating(event.slug))
        dispatch(DashboardActions.getScores(projectId))
        // const noOfScores =
        //     projectsByRating[projectId].usersThatScored.length + 1
        // setScores(
        //     scores.map(item => {
        //         if (Object.keys(item)[0] === projectId) {
        //             return {
        //                 [projectId]:
        //                     (score +
        //                         projectsByRating.score * (noOfScores - 1)) /
        //                     noOfScores,
        //             }
        //         }
        //         return item
        //     }),
        // )
    }

    useEffect(() => {
        if (project) {
            dispatch(DashboardActions.getScores(project._id))
            dispatch(DashboardActions.getComments(project._id))
            // console.log('comments', comments)
            setScore(project.score)
        }
    }, [dispatch, project])

    useEffect(() => {
        console.log('all', allScores)
        if (allScores) {
            setScore1(allScores.score1)
            setScore2(allScores.score2)
            setScore3(allScores.score3)
            setScore4(allScores.score4)
            setScore5(allScores.score5)
            setScore6(allScores.score6)
            setFeedback(allScores.feedback)
        }
    }, [allScores])

    if (!project) return null

    console.log(project)

    // console.log(user, project)

    let juryOrAdminClass =
        'bg-black text-white rounded-md p-8 py-4 text-lg hidden'

    // project.locationnew =
    //     project.locationnew === 'India' ? 'APAC' : project.locationnew

    if (user) {
        // console.log(user)
        // @ts-ignore
        if (user.roles.includes('SuperAdmin') || user.roles.includes('jury')) {
            // @ts-ignore
            if (!project.usersThatScored.includes(user.sub)) {
                juryOrAdminClass =
                    'bg-black text-white rounded-md p-8 py-4 text-lg'
            }
        }
    }

    const renderScoreButton = () => {
        if (EventHelpers.isVotingOpen(event, moment, userProfile.location)) {
            if (user) {
                // alert('you are user')
                // @ts-ignore
                if (
                    // @ts-ignore
                    user.roles.includes('SuperAdmin') ||
                    // @ts-ignore
                    user.roles.includes('jury')
                ) {
                    // alert('You have privileges to score this project')
                    if (!userProfile) {
                        return <div> </div>
                    }

                    if (userProfile.location !== project.locationnew) {
                        return <div> </div>
                    }
                    // @ts-ignore
                    if (project.usersThatScored.includes(user.sub) || scored) {
                        // alert('You have not scored this project yet')
                        // console.log(
                        //     '!project.usersThatScored.includes(user.sub)',
                        // )
                        return (
                            <div className="flex flex-col justify-center items-center">
                                <button
                                    onClick={handleModalOpen}
                                    className="bg-black text-white rounded-md p-8 py-4 text-lg"
                                >
                                    Update Scores
                                </button>
                            </div>
                        )
                    } else {
                        // console.log('else')
                        return (
                            <div className="flex flex-col justify-center items-center">
                                <button
                                    onClick={handleModalOpen}
                                    className="bg-black text-white rounded-md p-8 py-4 text-lg"
                                >
                                    Score
                                </button>
                            </div>
                        )
                    }
                } else return <div></div>
            }
        } else return <div></div>
    }

    const renderTrack = () => {
        const value = find(event.tracks, t => t.slug === project.track)

        if (!value) {
            return <Typography variant="subtitle1">No category</Typography>
        }
        return <Typography variant="subtitle1">{value.name}</Typography>
    }

    // const renderChallenges = challenges => {
    //     const values = filter(
    //         event.challenges,
    //         c => project.challenges.indexOf(c.slug) !== -1,
    //     )

    //     if (values.length === 0) {
    //         return <Typography variant="subtitle1">No challenges</Typography>
    //     }

    //     return values.map(challenge => (
    //         <Typography key={challenge.slug} variant="subtitle1">
    //             {challenge.name} ({challenge.partner})
    //         </Typography>
    //     ))
    // }

    const statusTag = status => {
        switch (status) {
            case 'final':
                return (
                    <Tag
                        label="Final"
                        // @ts-ignore
                        color={theme.palette.theme_turquoise.main}
                    />
                )
            case 'draft':
                return (
                    <Tag
                        label="Draft"
                        // @ts-ignore
                        color={theme.palette.theme_lightgray.main}
                    />
                )
            default:
                return null
        }
    }

    return (
        <>
            <Helmet>
                <title>{config.PLATFORM_OWNER_NAME}</title>
                <meta
                    name="keywords"
                    content="Hackathon, hackathon platform, Junction"
                />
                <meta
                    name="title"
                    content={
                        project ? `Harness Hackweek || ${project.name}` : ''
                    }
                />
                <meta
                    property="og:title"
                    content={
                        project ? `Harness Hackweek || ${project.name}` : ''
                    }
                />
                <meta
                    name="twitter:title"
                    content={
                        project ? `Harness Hackweek || ${project.name}` : ''
                    }
                />
                <meta
                    name="description"
                    content={project ? project.punchline : ''}
                />
                <meta
                    property="og:description"
                    content={project ? project.punchline : ''}
                />
                <meta
                    name="twitter:description"
                    content={project ? project.punchline : ''}
                />
                <meta name="og:type" content="website" />
                <meta
                    property="og:image"
                    content={
                        project.images[0]
                            ? project.images[0].url
                            : config.SEO_IMAGE_URL
                    }
                />
                <meta
                    name="twitter:image"
                    content={
                        project.images[0]
                            ? project.images[0].url
                            : config.SEO_IMAGE_URL
                    }
                />{' '}
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={config.SEO_TWITTER_HANDLE} />
                <meta
                    name="twitter:creator"
                    content={config.SEO_TWITTER_HANDLE}
                />
            </Helmet>

            <Box className={classes.wrapper}>
                <Tooltip title={pause ? 'Click to pause' : 'Click to play'}>
                    <Box
                        style={{ position: 'relative' }}
                        onClick={e => setPause(!pause)}
                    >
                        <AutoPlaySwipeableViews
                            enableMouseEvents
                            index={index}
                            onChangeIndex={setIndex}
                            interval={5000}
                            autoplay={pause}
                        >
                            {project.images.length > 0 ? (
                                project.images.map(image => (
                                    <Box
                                        key={image.publicId}
                                        className={classes.top}
                                    >
                                        <Image
                                            className={classes.image}
                                            url={image.url}
                                            defaultImage={require('assets/images/default_cover_image.png')}
                                        />
                                        {/* <img
                                            src="https://res.cloudinary.com/infrastructure-ambulance/image/upload/v1679292499/hackjunction/projects/newhackathonevent1/ZJCopFFSI/so8k7h2urlarwj86jyjk.png"
                                            alt="project image"
                                        /> */}
                                    </Box>
                                ))
                            ) : (
                                <Box className={classes.placeholderTop}>
                                    <Image
                                        className={classes.placeholderImage}
                                        publicId={event?.coverImage?.logo}
                                        defaultImage={require('assets/images/default_cover_image.png')}
                                    />
                                </Box>
                            )}
                        </AutoPlaySwipeableViews>
                        <Box className={classes.backButtonWrapper}>
                            <Button onClick={onBack} style={{ color: 'white' }}>
                                <ArrowBackIosIcon
                                    style={{ fontSize: '14px' }}
                                />
                                Back
                            </Button>
                        </Box>
                    </Box>
                </Tooltip>
                <Container center>
                    <Pagination
                        pages={project.images.length}
                        active={index}
                        onChange={setIndex}
                    />
                    <Box className={classes.content}>
                        <div className="flex items-center justify-between">
                            <div className="mb-2 md:mb-0">
                                <Typography variant="h4" gutterBottom>
                                    {project.name}
                                </Typography>
                                {typeof project.status !== 'undefined' &&
                                    statusTag(project.status)}
                            </div>
                            {user && (
                                <Scoring
                                    score={score}
                                    project={project}
                                    user={user}
                                    slug={event.slug}
                                    event={event}
                                />
                            )}
                        </div>

                        <Box mt={5} mb={5}>
                            <Typography
                                variant="subtitle1"
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                {project.punchline}
                            </Typography>
                            {
                                // @ts-ignore
                            }
                            <Markdown
                                // @ts-ignore
                                source={project.description}
                            />
                        </Box>
                        {showTableLocation && project.locationnew && (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Location
                                </Typography>
                                <Typography variant="subtitle1">
                                    {project.locationnew === 'India'
                                        ? 'APAC'
                                        : project.locationnew}
                                </Typography>
                            </Box>
                        )}
                        {project.video ? (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    video
                                </Typography>
                                {/* <a
                                    href={project.video}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {project.video}
                                </a> */}
                                <div className={classes.playerWrapper}>
                                    <ReactPlayer
                                        url={project.video}
                                        className={classes.reactPlayer}
                                        width="100%"
                                        height="auto"
                                        controls
                                        light={false}
                                        loop={false}
                                        playbackRate={1.0}
                                        volume={0.8}
                                        muted={false}
                                        onReady={() => console.log('onReady')}
                                        onStart={() => console.log('onStart')}
                                        onBuffer={() => console.log('onBuffer')}
                                        onSeek={e => console.log('onSeek', e)}
                                        onError={e => console.log('onError', e)}
                                    />
                                </div>
                            </Box>
                        ) : (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    video
                                </Typography>
                                <Typography variant="subtitle1">
                                    No video available
                                </Typography>
                            </Box>
                        )}

                        {project.demo ? (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Demo
                                </Typography>
                                <a
                                    href={project.demo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {project.demo}
                                </a>
                            </Box>
                        ) : (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Demo
                                </Typography>
                                <Typography variant="subtitle1">
                                    No demo available
                                </Typography>
                            </Box>
                        )}
                        {!project.sourcePublic ? (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Source code
                                </Typography>
                                <Typography variant="subtitle1">
                                    Source code not public
                                </Typography>
                            </Box>
                        ) : (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Source code
                                </Typography>
                                <a
                                    href={project.source}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {project.source}
                                </a>
                            </Box>
                        )}
                        {event && project.track && (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Category
                                </Typography>
                                {renderTrack()}
                            </Box>
                        )}
                        {/* {event && project.challenges.length > 0 && (
                            <Box mb={3}>
                                <Typography
                                    variant="h6"
                                    className={classes.sectionTitle}
                                >
                                    Challenges
                                </Typography>
                                {renderChallenges()}
                            </Box>
                        )} */}
                        <Typography
                            variant="h6"
                            className={classes.sectionTitle}
                        >
                            Team
                        </Typography>
                        <ProjectTeam
                            // @ts-ignore
                            hiddenUsers={project.hiddenMembers}
                            teamId={project.team}
                            showFullTeam={showFullTeam}
                        />
                        <AdditionalFiles project={project} />
                        {project.addOn && (
                            <Box mt={5} mb={5}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}
                                >
                                    Additional Information
                                </Typography>
                                {
                                    // @ts-ignore
                                }
                                <Markdown
                                    // @ts-ignore
                                    source={project.addOn}
                                />
                            </Box>
                        )}
                    </Box>
                    {renderScoreButton()}
                    <Modal open={open} onClose={handleModalClose}>
                        <div className="max-h-[90vh] overflow-y-auto">
                            <Box sx={modalStyle}>
                                <Typography variant="h4" gutterBottom>
                                    Score
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {project.name}
                                </Typography>
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Innovation and Creativity:{' '}
                                    </span>
                                    Evaluates the originality of the idea and
                                    creativity in solving problems, applicable
                                    to both technical solutions and strategic
                                    initiatives.
                                </p>

                                <RadioButtons
                                    score={score1}
                                    setScore={setScore1}
                                    name="score1"
                                />
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Impact and Strategic Alignment:{' '}
                                    </span>
                                    Assesses the project's alignment with the
                                    company’s strategic goals and its potential
                                    to make a significant positive impact on the
                                    organization.
                                </p>
                                <RadioButtons
                                    score={score2}
                                    setScore={setScore2}
                                    name="score2"
                                />
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Implementation Readiness:{' '}
                                    </span>
                                    Measures the project's readiness for
                                    implementation, including its scalability
                                    and integration into existing processes or
                                    products.
                                </p>
                                <RadioButtons
                                    score={score3}
                                    setScore={setScore3}
                                    name="score3"
                                />
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Commercial Viability:{' '}
                                    </span>
                                    Considers the potential of the project to
                                    enhance revenue, improve market position,
                                    and increase customer engagement.
                                </p>
                                <RadioButtons
                                    score={score4}
                                    setScore={setScore4}
                                    name="score4"
                                />
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Cross-functional Collaboration:{' '}
                                    </span>
                                    Championing our value Celebrate Together,
                                    this category evaluates the effectiveness of
                                    collaboration among team members from
                                    different departments, or locations,
                                    highlighting how these efforts enhance the
                                    project’s innovation and execution.
                                </p>
                                <RadioButtons
                                    score={score5}
                                    setScore={setScore5}
                                    name="score5"
                                />
                                <p className="text-gray-500">
                                    <span className="font-bold">
                                        Presentation and Communication:{' '}
                                    </span>
                                    Focuses on the clarity and persuasiveness of
                                    the project presentation, assessing how
                                    effectively the team communicates the value
                                    of their project and responds to questions
                                    about it. For technical hacks, this would
                                    also assess the technical detailing of the
                                    presentation.
                                </p>
                                <RadioButtons
                                    score={score6}
                                    setScore={setScore6}
                                    name="score6"
                                />
                                <p className="text-gray-500">
                                    Do you have any other feedback on this
                                    project?
                                </p>
                                <textarea
                                    className="w-full h-24 border border-gray-300 rounded-md p-2"
                                    value={feedback}
                                    onChange={e => setFeedback(e.target.value)}
                                />
                                <div className="flex justify-between">
                                    <button
                                        onClick={() => {
                                            const totalScore = (
                                                score1 * 0.25 +
                                                score2 * 0.25 +
                                                score3 * 0.2 +
                                                score4 * 0.2 +
                                                score5 * 0.05 +
                                                score6 * 0.05
                                            ).toFixed(2)

                                            let noOfScores =
                                                project.usersThatScored.length +
                                                scored

                                            let previosScore =
                                                score * noOfScores
                                            if (
                                                project.usersThatScored.includes(
                                                    user.sub,
                                                ) ||
                                                scored
                                            ) {
                                                noOfScores = noOfScores - 1
                                                const previousScoreFromUser = (
                                                    allScores.score1 * 0.25 +
                                                    allScores.score2 * 0.25 +
                                                    allScores.score3 * 0.2 +
                                                    allScores.score4 * 0.2 +
                                                    allScores.score5 * 0.05 +
                                                    allScores.score6 * 0.05
                                                ).toFixed(2)
                                                previosScore =
                                                    previosScore -
                                                    previousScoreFromUser
                                            } else {
                                                setScored(1)
                                            }
                                            noOfScores++

                                            // @ts-ignore
                                            const newScore = parseFloat(
                                                // @ts-ignore
                                                (parseFloat(previosScore) +
                                                    // @ts-ignore
                                                    parseFloat(totalScore)) /
                                                    parseFloat(noOfScores),
                                            ).toFixed(2)

                                            // @ts-ignore
                                            setScore(newScore)

                                            dispatch(
                                                DashboardActions.giveAllScores(
                                                    project._id,
                                                    {
                                                        score1: score1,
                                                        score2: score2,
                                                        score3: score3,
                                                        score4: score4,
                                                        score5: score5,
                                                        score6: score6,
                                                        feedback: feedback,
                                                    },
                                                ),
                                            )

                                            dispatch(
                                                DashboardActions.getScores(
                                                    project._id,
                                                ),
                                            )

                                            handleScoring(
                                                project._id,
                                                totalScore,
                                                feedback,
                                            )

                                            handleModalClose()
                                            // DashboardActions.giveFeedback(
                                            //     project.id,
                                            //     feedback,
                                            // )
                                        }}
                                        className="bg-black text-white px-4 py-2 rounded-md"
                                    >
                                        Score
                                    </button>
                                    <button
                                        onClick={handleModalClose}
                                        className="bg-gray-600 text-white px-4 py-2 rounded-md"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Box>
                        </div>
                    </Modal>
                    {/* <Comments data={ } /> */}
                    <div className="bg-black text-white rounded-md p-8 py-4 text-lg hidden"></div>
                    <div className="w-full p-8">
                        {
                            <Comments
                                data={comments}
                                user={user}
                                projectId={project._id}
                            />
                        }
                    </div>
                    <Box height={200} />
                </Container>
            </Box>
        </>
    )
}

export default ProjectDetail
