import React, { useCallback, useEffect, useState } from 'react'

import { sortBy } from 'lodash-es'
import moment from 'moment-timezone'
import { Grid } from '@material-ui/core'
import { EventHelpers } from '@hackjunction/shared'
import ProjectsGridItem from '../ProjectsGridItem'
import { makeStyles } from '@material-ui/core/styles'
import ProjectScoresService from 'services/projectScores'
import * as UserSelectors from 'redux/user/selectors'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
    select: {
        cursor: 'pointer',
    },
}))

const ProjectsGrid = ({
    projects,
    event,
    onSelect,
    sortField = 'location',
    showFullTeam = false,
    showScore = false,
    token = '',
}) => {
    const userProfile = useSelector(UserSelectors.userProfile)
    const classes = useStyles()
    const isOngoingEvent = EventHelpers.isEventOngoing(
        event,
        moment,
        userProfile.location,
    )
    const [sorted, setSorted] = useState(projects)
    const [location, setLocation] = useState('')
    const [category, setCategory] = useState('')
    const fetchData = useCallback(async () => {
        const nprojects = await Promise.all(
            projects.map(async project => {
                return ProjectScoresService.getScoreByEventSlugAndProjectIdAndPartnerToken(
                    token,
                    event.slug,
                    project._id,
                )
                    .then(score => {
                        if (score[0]) {
                            return Object.assign(score[0], project)
                        }
                        return Object.assign(
                            { score: 0, message: 'Not rated' },
                            project,
                        )
                    })
                    .catch(e => {
                        console.log(e)
                        return Object.assign(
                            { score: 0, message: 'Not rated' },
                            project,
                        )
                    })
            }),
        )
        setSorted((sortBy(nprojects, p => -p['score']): nprojects))
    }, [event.slug, projects, token])

    useEffect(() => {
        if (showScore) {
            fetchData()
        } else {
            setSorted(
                sortField ? sortBy(projects, p => p[sortField]) : projects,
            )
        }
    }, [fetchData, projects, showScore, sortField])

    const filtered = sorted.filter(project => {
        if (location && project.locationnew !== location) {
            return false
        }
        if (category && project.track !== category) {
            return false
        }
        return true
    })

    const convertCategorySlugToName = slug => {
        if (!event) return slug
        if (!event.tracks) return slug

        const track = event.tracks.find(track => track.slug === slug)
        return track ? track.name : slug
    }

    // const filtered
    return (
        <div className="flex flex-col justify-center items-center">
            <h1 className="text-center font-bold text-3xl mt-4">Projects</h1>
            <div className="flex items-center justify-center max-w-3xl">
                <select
                    className={`${classes.select} border border-gray-300 rounded-md p-2 mt-4 flex-1 mr-2 w-full`}
                    value={location}
                    onChange={e => setLocation(e.target.value)}
                >
                    <option value="">All locations</option>
                    <option value="EMEA">EMEA</option>
                    <option value="India">APAC</option>
                    <option value="Americas">AMERICAS</option>
                    {/* {event.locations.map(location => (
                    <option value={location}>{location}</option>
                ))} */}
                </select>
                <select
                    className={`${classes.select} border border-gray-300 rounded-md p-2 mt-4 flex-1 mr-2`}
                    value={category}
                    onChange={e => setCategory(e.target.value)}
                >
                    <option value="">All categories</option>
                    {event.tracks.map(category => (
                        <option value={category.slug}>{category.name}</option>
                    ))}
                </select>
            </div>
            {filtered.length === 0 && (
                <div className="text-center mt-4">
                    No projects found
                    {category && (
                        <span>
                            {' '}
                            under{' '}
                            <span className="font-bold">
                                {convertCategorySlugToName(category)}
                            </span>
                        </span>
                    )}
                    {location && (
                        <span>
                            {' '}
                            in{' '}
                            <span className="font-bold">
                                {location === 'India' ? 'APAC' : location}
                            </span>
                        </span>
                    )}
                    , please change your filters and try agian.
                </div>
            )}
            <Grid
                container
                spacing={5}
                direction="row"
                alignItems="stretch"
                justify="center"
                style={{ marginTop: '36px' }}
            >
                {filtered.map(project => (
                    <ProjectsGridItem
                        project={project}
                        event={event}
                        showTableLocation={isOngoingEvent}
                        showFullTeam={showFullTeam}
                        onClickMore={() => onSelect(project)}
                        score={project?.score}
                        message={project?.message}
                        showTags={true}
                    />
                ))}
            </Grid>{' '}
        </div>
    )
}

export default ProjectsGrid
