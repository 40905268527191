import React from 'react'

class ErrroBoundary extends React.Component {
    state = { hasError: false }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        console.error('ErrorBoundary caught an error', error, errorInfo)
        fetch('/api/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                error,
                errorInfo,
            }),
        })
        // window.location.reload()
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="flex justify-center items-center h-screen">
                    <h1 className="text-center text-xl">
                        {this.props.fallback}
                    </h1>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrroBoundary
