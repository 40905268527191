import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Typography,
} from '@material-ui/core'
import { sortBy } from 'lodash-es'
import moment from 'moment'
import MiscUtils from 'utils/misc'
import TimelineDot from 'components/generic/TimelineDot'
import StepConnector from '@material-ui/core/StepConnector'
import Container from 'components/generic/Container'
import { useSelector } from 'react-redux'
import * as UserSelectors from 'redux/user/selectors'
import { concatAndCapitalize } from '@hackjunction/shared/helpers/utils'

const useStyles = makeStyles(theme => ({
    root: {
        background: 'transparent',
    },
    borderContent: {
        borderColor: props => props.accentColor || '#19DDEA',
        paddingTop: '8px',
        marginTop: '-9px',
        marginLeft: '6px',
        textTransform: 'uppercase',
        color: props => props.textColor,
    },
    date: {
        fontWeight: 'bold',
        paddingTop: '2px',
        fontSize: '18px',
    },
    label: {
        marginTop: '-9px',
        '& .MuiStepLabel-label': {
            color: props => props.textColor,
            opacity: 0.54,
        },
        '& .MuiStepLabel-active': {
            opacity: 0.87,
        },
    },
}))

const colorLibStyle = props => ({
    root: {
        marginLeft: '6px',
        paddingBottom: 0,
    },
    active: {
        '& $line': {
            borderColor: props => props.accent || '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: props => props.accent || '#784af4',
        },
    },
    line: {
        borderColor: props => props.accent || '#19DDEA',

        borderRadius: 1,
    },
    lineVertical: {
        borderColor: props => props.accent || '#19DDEA',
        padding: 0,

        borderRadius: 1,
    },
})

const ColorlibConnector = withStyles(colorLibStyle())(StepConnector)

ColorlibConnector.propTypes = {
    ...ColorlibConnector.propTypes,
    accent: PropTypes.string,
}
function differentYear(
    event,
    registrationStartTimeAlias,
    registrationEndTimeAlias,
    startTimeAlias,
    endTimeAlias,
) {
    const currentYear = moment()

    return (
        currentYear.diff(event[registrationStartTimeAlias], 'years') ||
        currentYear.diff(event[registrationEndTimeAlias], 'years') ||
        currentYear.diff(event[startTimeAlias], 'years') ||
        currentYear.diff(event[endTimeAlias], 'years')
    )
}
const EventTimeline = ({ event, textColor, accentColor = undefined }) => {
    const classes = useStyles({ accentColor, textColor })
    const userProfile = useSelector(UserSelectors.userProfile)

    let registrationStartTimeAlias = 'registrationStartTime'
    let registrationEndTimeAlias = 'registrationEndTime'
    let startTimeAlias = 'startTime'
    let endTimeAlias = 'endTime'

    if (userProfile.location) {
        registrationEndTimeAlias = concatAndCapitalize(
            registrationEndTimeAlias,
            userProfile.location,
        )
        registrationStartTimeAlias = concatAndCapitalize(
            registrationStartTimeAlias,
            userProfile.location,
        )
        startTimeAlias = concatAndCapitalize(
            startTimeAlias,
            userProfile.location,
        )
        endTimeAlias = concatAndCapitalize(endTimeAlias, userProfile.location)
    }

    const dateString = differentYear(
        event,
        registrationStartTimeAlias,
        registrationEndTimeAlias,
        startTimeAlias,
        endTimeAlias,
    )
        ? 'MMM D YYYY'
        : 'MMM D'
    const timelineItems = useMemo(() => {
        const realItems = event.eventTimeline.items.map(item => {
            return {
                date: moment(item[startTimeAlias]).format(dateString),
                dateValue: moment(item[startTimeAlias]).unix(),
                completed: moment(item[startTimeAlias]).isBefore(),
                title: item.title,
                active: true,
            }
        })
        const items =
            realItems.length > 0
                ? realItems
                : [
                      {
                          date: moment(
                              event[registrationStartTimeAlias],
                          ).format(dateString),
                          dateValue: moment(
                              event[registrationStartTimeAlias],
                          ).unix(),
                          completed: moment(
                              event[registrationStartTimeAlias],
                          ).isBefore(),
                          title: 'Application period begins',
                          active: true,
                      },
                      {
                          date: moment(event[registrationEndTimeAlias]).format(
                              dateString,
                          ),
                          dateValue: moment(
                              event[registrationEndTimeAlias],
                          ).unix(),
                          completed: moment(
                              event[registrationEndTimeAlias],
                          ).isBefore(),
                          title: 'Application period ends',
                          active: true,
                      },
                  ]

        if (realItems.length < 1) {
            if (
                moment(event[registrationEndTimeAlias]).isBetween(
                    event[startTimeAlias],
                    event[endTimeAlias],
                )
            ) {
                items.push({
                    date: moment(event[startTimeAlias]).format(dateString),
                    dateValue: moment(event[startTimeAlias]).unix(),
                    completed: moment(event[startTimeAlias]).isBefore(),
                    title: event.name + ' begins',
                    active: true,
                })
                items.push({
                    date: moment(event[endTimeAlias]).format(dateString),
                    dateValue: moment(event[endTimeAlias]).unix(),
                    completed: moment(event[endTimeAlias]).isBefore(),
                    title: event.name + ' ends',
                    active: true,
                })
            } else {
                items.push({
                    date: MiscUtils.formatPDFDateInterval(
                        event[startTimeAlias],
                        event[endTimeAlias],
                    ),
                    dateValue: moment(event[startTimeAlias]).unix(),
                    completed: moment(event[endTimeAlias]).isBefore(),
                    title: event.name,
                    active: true,
                })
            }
        }

        const sorted = sortBy(items, 'dateValue')

        return sorted
    }, [
        dateString,
        event[endTimeAlias],
        event.eventTimeline,
        event.name,
        event[registrationEndTimeAlias],
        event[registrationStartTimeAlias],
        event[startTimeAlias],
    ])

    return (
        <Container>
            <Stepper
                className={classes.root}
                activeStep={0}
                orientation="vertical"
                connector={<ColorlibConnector accent={accentColor} />}
            >
                {timelineItems.map(item => (
                    <Step
                        key={item.date + item.title}
                        active={item.active}
                        completed={item.completed}
                        expanded
                    >
                        <StepLabel
                            StepIconComponent={props => (
                                <TimelineDot
                                    {...props}
                                    accentColor={accentColor}
                                />
                            )}
                            className={classes.label}
                        >
                            <Typography
                                variant="button"
                                className={classes.date}
                            >
                                {item.date}
                            </Typography>
                        </StepLabel>
                        <StepContent className={classes.borderContent}>
                            <Typography variant="subtitle2">
                                {item.title}
                            </Typography>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Container>
    )
}

export default EventTimeline
