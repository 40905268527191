const expertise = [
    'Programming Languages',		
    'Front-end Development',
    'Back-end Development',
    'Mobile App Development',
    'Data Science and Analysis',
    'AI and Machine Learning	',	
    'Cybersecurity',
    'DevOps and System Administration',
    'UI/UX Design',
    'Graphic Design',
    'Video Production and Editing',
    'Project Management',
    'Business Development and Strategy',
    'Marketing and Social Media',
    'Team Leadership',
    'Communication',
    'Problem-solving',
    'Creativity',
    'Adaptability',
    'Presentation'
]
module.exports = expertise
