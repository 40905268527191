import StarSvg from 'components/starSvg'
import React, { useEffect } from 'react'
import * as DashboardActions from 'redux/dashboard/actions'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import { useDispatch, useSelector } from 'react-redux'

const Rating = ({ project, user, slug }) => {
    const dispatch = useDispatch()
    const [staredProject, setStaredProject] = React.useState(false)
    const [rating, setRating] = React.useState(0)

    useEffect(() => {
        // dispatch(DashboardActions.getProjectsByRating(event.slug))
        if (project && user) {
            // @ts-ignore
            if (project.usersThatRated.includes(user.sub)) {
                setStaredProject(true)
            }
        }
        if (project) {
            if (project.usersThatRated.includes(user.sub)) {
                setStaredProject(true)
            }
            setRating(project.rating)
        }
    }, [project, user])

    // const renderScore = () => {
    //     const score = scores.map(item => {
    //         if (Object.keys(item)[0] === project._id) {
    //             console.log('from state score', Object.values(item)[0])
    //             return Object.values(item)[0]
    //         }
    //     })
    //     if (score.length > 0) {
    //         return score[0]
    //     }
    //     return 0
    // }

    return (
        <div className="flex">
            <div className="flex items-center mb-2">
                <button
                    onClick={e => {
                        e.stopPropagation()
                        if (staredProject) {
                            dispatch(
                                DashboardActions.unrateProject(
                                    project._id,
                                    // @ts-ignore
                                    user.sub,
                                    slug,
                                ),
                            )
                            setStaredProject(false)
                            const newRating = rating - 1
                            setRating(newRating)
                        } else {
                            dispatch(
                                DashboardActions.rateProject(
                                    project._id,
                                    // @ts-ignore
                                    user.sub,
                                    slug,
                                ),
                                // @ts-ignore
                            )
                            setStaredProject(true)
                            const newRating = rating + 1
                            setRating(newRating)
                        }
                    }}
                    disabled={!user}
                >
                    {staredProject ? (
                        <StarSvg on={true} />
                    ) : (
                        <StarSvg on={false} />
                    )}
                </button>
            </div>
        </div>
    )
}

export default Rating
