const mongoose = require('mongoose')
const { GraphQLObjectType, GraphQLString, GraphQLList } = require('graphql')
const Expertise = require('../constants/expertise')

const mongooseSchema = new mongoose.Schema({
    expertise: {
        type: [String],
        validate: {
            validator(v) {
                return Expertise.indexOf(v) !== -1
            },
            message: () => `Expertise is not valid`,
        },
    },
})

const graphqlSchema = new GraphQLObjectType({
    name: 'Expertise',
    fields: () => ({
        expertise: {
            // array of strings
            type: new GraphQLList(GraphQLString),
        },
    }),
})

module.exports = {
    mongoose: mongooseSchema,
    graphql: graphqlSchema,
}
