import { createSelector } from 'reselect'
import { isEmpty } from 'lodash-es'
import moment from 'moment'
import {
    EventHelpers,
    EventStatuses,
    EventTypes,
    RegistrationStatuses,
    ReviewingMethods,
    // RegistrationTravelGrantStatuses as TravelGrantStatuses
} from '@hackjunction/shared'
// import UserProfilesService from 'services/userProfiles'
// import store from 'redux/configureStore'
// import reducer from 'redux/rootReducer'
// import Auth0Service from 'services/auth0'
// import { idTokenData } from 'redux/auth/selectors'
// import { useSelector } from 'react-redux'
// import * as UserSelectors from 'redux/user/selectors'

export const userProfile = state => state.user.profile
export const scores = state => state.dashboard.scores.data
export const comments = state => state.dashboard.comments.data
export const event = state => state.dashboard.event.data
export const eventLoading = state => state.dashboard.event.loading
export const eventError = state => state.dashboard.event.error
export const eventUpdated = state => state.dashboard.event.updated
export const projectsByRating = state => state.dashboard.projectsByRating.data
export const top10projects = state => state.dashboard.finalistTop10.data

export const registration = state => state.dashboard.registration.data
export const registrationLoading = state => state.dashboard.registration.loading
export const registrationError = state => state.dashboard.registration.error
export const registrationUpdated = state => state.dashboard.registration.updated

export const team = state => state.dashboard.team.data
export const teams = state => state.dashboard.teams.data
export const teamLoading = state => state.dashboard.team.loading
export const teamError = state => state.dashboard.team.error
export const teamUpdated = state => state.dashboard.team.updated

export const projects = state => state.dashboard.projects.data
export const projectsLoading = state => state.dashboard.projects.loading
export const projectsError = state => state.dashboard.projects.error
export const projectsUpdated = state => state.dashboard.projects.updated

export const annotator = state => state.dashboard.annotator.data
export const annotatorLoading = state => state.dashboard.annotator.loading
export const annotatorError = state => state.dashboard.annotator.error
export const annotatorUpdated = state => state.dashboard.annotator.updated

export const projectScores = state => state.dashboard.project_scores.data
export const projectScoresLoading = state =>
    state.dashboard.project_scores.loading
export const projectScoresError = state => state.dashboard.project_scores.error
export const projectScoresUpdated = state =>
    state.dashboard.project_scores.updated

export const annotatorVoteCount = createSelector(annotator, annotator => {
    if (!annotator) return 0
    return Math.max(annotator.ignore.length - annotator.skipped.length - 1, 0)
})

export const registrations = state => state.dashboard.registrations.data
export const registrationsMap = state => state.dashboard.registrations.map
export const registrationsLoading = state =>
    state.dashboard.registrations.loading

export const allTeams = state => state.dashboard.allTeams.data
export const allTeamsLoading = state => state.dashboard.allTeams.loading

// const userProfile = UserProfilesService.getUserProfile(idToken)

// export const eventStatus = createSelector(event, async event => {
//     const authSession = await Auth0Service.checkSession()
//     if (!authSession) {
//         return EventHelpers.getEventStatus(event, moment)
//     }
//     const userProfile = await UserProfilesService.getUserProfile(
//         authSession.idToken,
//     )
//     if (!userProfile) {
//         return EventHelpers.getEventStatus(event, moment)
//     }

//     return EventHelpers.getEventStatus(event, moment, userProfile.location)
// })

export const eventStatus = createSelector(
    event,
    userProfile,
    (event, userProfile) => {
        if (!userProfile) {
            return EventHelpers.getEventStatus(event, moment)
        }
        return EventHelpers.getEventStatus(event, moment, userProfile.location)
    },
)

export const isRegistrationOpen = createSelector(
    eventStatus,
    status => status === EventStatuses.REGISTRATION_OPEN.id,
)
export const isSubmissionsUpcoming = createSelector(
    event,
    userProfile,
    (event, userProfile) => {
        if (!userProfile) {
            return EventHelpers.isSubmissionsUpcoming(event, moment)
        }

        return EventHelpers.isSubmissionsUpcoming(
            event,
            moment,
            userProfile.location,
        )
    },
)
// export const isSubmissionsPast = createSelector(event, async event => {
//     const authSession = await Auth0Service.checkSession()
//     if (!authSession) {
//         return EventHelpers.isSubmissionsPast(event, moment)
//     }
//     const userProfile = await UserProfilesService.getUserProfile(
//         authSession.idToken,
//     )
//     if (!userProfile) {
//         return EventHelpers.isSubmissionsPast(event, moment)
//     }

//     return EventHelpers.isSubmissionsPast(event, moment, userProfile.location)
// })

export const isSubmissionsPast = createSelector(
    event,
    userProfile,
    (event, userProfile) => {
        if (!userProfile) {
            return EventHelpers.isSubmissionsPast(event, moment)
        }
        return EventHelpers.isSubmissionsPast(
            event,
            moment,
            userProfile.location,
        )
    },
)

export const isAcceptancePending = createSelector(
    registration,
    registration => {
        return (
            [
                RegistrationStatuses.asObject.pending.id,
                RegistrationStatuses.asObject.softAccepted.id,
                RegistrationStatuses.asObject.softRejected.id,
            ].indexOf(registration.status) !== -1
        )
    },
)

export const appliedAsTeam = createSelector(registration, registration => {
    if (
        !registration ||
        !registration.hasOwnProperty('answers') ||
        !registration.answers.hasOwnProperty('teamOptions')
    ) {
        return false
    } else {
        return registration.answers.teamOptions.applyAsTeam
    }
})

export const hasTeam = createSelector(team, team => {
    return !isEmpty(team)
})

export const isTeamComplete = createSelector(team, team => {
    if (isEmpty(team)) {
        return false
    } else {
        return team.complete
    }
})

export const isTeamValid = createSelector(team, team => {
    if (!team) return false
    return (
        [team.owner]
            .concat(team.members)
            .map(userId => {
                return team.meta[userId]
            })
            .filter(member => {
                if (
                    member.registration.status !==
                    RegistrationStatuses.asObject.checkedIn.id
                ) {
                    return true
                }
                return false
            }).length === 0
    )
})

// export const lockedPages = createSelector(event, async event => {
//     const authSession = await Auth0Service.checkSession()
//     if (!authSession) {
//         return {
//             submissions: !EventHelpers.isSubmissionsOpen(event, moment),
//             reviewing: EventHelpers.isVotingPast(event, moment),
//             team: EventHelpers.isSubmissionsPast(event, moment),
//             winner: EventHelpers.isSubmissionsClosed(event, moment),
//             finalistVoting: !EventHelpers.isFinalistVotingOpen(event, moment),
//         }
//     }
//     const userProfile = await UserProfilesService.getUserProfile(
//         authSession.idToken,
//     )
//     if (!userProfile) {
//         return {
//             submissions: !EventHelpers.isSubmissionsOpen(event, moment),
//             reviewing: EventHelpers.isVotingPast(event, moment),
//             team: EventHelpers.isSubmissionsPast(event, moment),
//             winner: EventHelpers.isSubmissionsClosed(event, moment),
//             finalistVoting: !EventHelpers.isFinalistVotingOpen(event, moment),
//         }
//     }

//     return {
//         submissions: !EventHelpers.isSubmissionsOpen(
//             event,
//             moment,
//             userProfile.location,
//         ),
//         reviewing: EventHelpers.isVotingPast(
//             event,
//             moment,
//             userProfile.location,
//         ),
//         team: EventHelpers.isSubmissionsPast(
//             event,
//             moment,
//             userProfile.location,
//         ),
//         winner: EventHelpers.isSubmissionsClosed(
//             event,
//             moment,
//             userProfile.location,
//         ),
//         finalistVoting: !EventHelpers.isFinalistVotingOpen(
//             event,
//             moment,
//             userProfile.location,
//         ),
//     }
// })

export const lockedPages = createSelector(
    event,
    userProfile,
    (event, userProfile) => {
        if (!userProfile) {
            return {
                submissions: !EventHelpers.isSubmissionsOpen(event, moment),
                reviewing: EventHelpers.isVotingPast(event, moment),
                team: EventHelpers.isSubmissionsPast(event, moment),
                winner: EventHelpers.isSubmissionsClosed(event, moment),
                finalistVoting: !EventHelpers.isFinalistVotingOpen(
                    event,
                    moment,
                ),
            }
        }

        return {
            submissions: !EventHelpers.isSubmissionsOpen(
                event,
                moment,
                userProfile.location,
            ),
            reviewing: EventHelpers.isVotingPast(
                event,
                moment,
                userProfile.location,
            ),
            team: EventHelpers.isSubmissionsPast(
                event,
                moment,
                userProfile.location,
            ),
            winner: EventHelpers.isSubmissionsClosed(
                event,
                moment,
                userProfile.location,
            ),
            finalistVoting: !EventHelpers.isFinalistVotingOpen(
                event,
                moment,
                userProfile.location,
            ),
        }
    },
)

export const shownPages = createSelector(
    event,
    registration,
    (event, registration) => {
        const STATUSES = RegistrationStatuses.asObject

        return {
            submissions: registration?.status === STATUSES.checkedIn.id,
            eventID:
                event?.eventType === EventTypes.physical.id &&
                [
                    STATUSES.confirmed.id,
                    STATUSES.confirmedToHub.id,
                    STATUSES.checkedIn.id,
                ].indexOf(registration?.status) !== -1,
            reviewing:
                registration?.status === STATUSES.checkedIn.id &&
                event?.reviewMethod === ReviewingMethods.gavelPeerReview.id,
            rating:
                registration?.status === STATUSES.checkedIn.id &&
                event?.reviewMethod === ReviewingMethods.gavelPeerReview.id,
            travelGrant:
                registration?.status === STATUSES.checkedIn.id &&
                (registration?.travelGrant ?? 0) > 0,
            // finalistVoting:
            //     registration?.status === STATUSES.checkedIn.id &&
            //     event.overallReviewMethod !== 'noOverallWinner',
            finalistVoting: false,
            hackerPack:
                [
                    STATUSES.checkedIn.id,
                    STATUSES.confirmed.id,
                    STATUSES.confirmedToHub.id,
                ].indexOf(registration?.status) !== -1,
        }
    },
)
