const division = [
        'Admin/Executive',
        'Customer Success',
        'Design',
        'FISCAL Ops',
        'Marketing',
        'People & Places',
        'Product',
        'R&D',
        'Revenue',
        'Sales'
]
module.exports = division
