import StarSvg from 'components/starSvg'
import React, { useEffect } from 'react'
import * as DashboardActions from 'redux/dashboard/actions'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { EventHelpers } from '@hackjunction/shared'
import moment from 'moment'

const Scoring = ({ project, user, score, slug, event }) => {
    const dispatch = useDispatch()
    const [staredProject, setStaredProject] = React.useState(false)
    const [rating, setRating] = React.useState(0)

    useEffect(() => {
        // dispatch(DashboardActions.getProjectsByRating(event.slug))
        if (project && user) {
            // @ts-ignore
            if (project.usersThatRated.includes(user.sub)) {
                setStaredProject(true)
            }
        }
        if (project) {
            if (project.usersThatRated.includes(user.sub)) {
                setStaredProject(true)
            }
            setRating(project.rating)
        }
    }, [project, user])

    return (
        <div className="flex flex-col items-end">
            <div className="flex items-center mb-2">
                <button
                    onClick={e => {
                        e.stopPropagation()
                        if (staredProject) {
                            dispatch(
                                DashboardActions.unrateProject(
                                    project._id,
                                    // @ts-ignore
                                    user.sub,
                                    slug,
                                ),
                            )
                            setStaredProject(false)
                            const newRating = rating - 1
                            setRating(newRating)
                        } else {
                            dispatch(
                                DashboardActions.rateProject(
                                    project._id,
                                    // @ts-ignore
                                    user.sub,
                                    slug,
                                ),
                                // @ts-ignore
                            )
                            setStaredProject(true)
                            const newRating = rating + 1
                            setRating(newRating)
                        }
                    }}
                    disabled={!user}
                >
                    {staredProject ? (
                        <StarSvg on={true} />
                    ) : (
                        <StarSvg on={false} />
                    )}
                </button>

                {
                    // rating of the project
                }
                <div className="ml-2">{rating}</div>
            </div>
            <div className="flex items-center">
                {project.globalScore !== null &&
                    EventHelpers.isEventOver(
                        event,
                        moment,
                        // userProfile.location,
                    ) && (
                        <Typography
                            style={{
                                textTransform: 'uppercase',
                            }}
                            variant="body1"
                        >
                            Global Score:{' '}
                            {parseFloat(project.globalScore).toFixed(2)}
                        </Typography>
                    )}
            </div>
            <div className="flex items-center">
                {project.score !== null ? (
                    <Typography
                        style={{
                            textTransform: 'uppercase',
                        }}
                        variant="body1"
                    >
                        Regional Score: {parseFloat(project.score).toFixed(2)}
                    </Typography>
                ) : (
                    score !== null && (
                        <Typography
                            style={{ textTransform: 'uppercase' }}
                            variant="body1"
                        >
                            Regional Score: {parseFloat(score).toFixed(2)}
                        </Typography>
                    )
                )}
            </div>
        </div>
    )
}

export default Scoring
