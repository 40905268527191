import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useRouteMatch } from 'react-router'
import { EventStatuses } from '@hackjunction/shared'
import { Typography, Grid } from '@material-ui/core'
import { EventHelpers } from '@hackjunction/shared'
import Button from 'components/generic/Button'
import * as AuthSelectors from 'redux/auth/selectors'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'
import * as UserSelectors from 'redux/user/selectors'

export default ({ event, registration }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const match = useRouteMatch()
    const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
    const hasJudge = useSelector(AuthSelectors.hasJudge)
    const userProfile = useSelector(UserSelectors.userProfile)
    const hasGlobalJudge = useSelector(AuthSelectors.hasGlobalJudge)
    const hasSuperAdmin = useSelector(AuthSelectors.hasSuperAdmin)
    const hasRegistration = registration
        ? registration.status !== 'incomplete'
        : false
    if (
        EventHelpers.isFinalistVotingOpen(event, moment, userProfile.location)
    ) {
        if (hasSuperAdmin || hasGlobalJudge) {
            return (
                <Button
                    onClick={() => dispatch(push(`/finalist/${event.slug}`))}
                    variant="applicationsClosed"
                    color="theme_blue"
                >
                    Finalist
                </Button>
            )
        }
    }
    switch (event?._eventStatus) {
        case EventStatuses.PUBLISHED.id: {
            return (
                <Button
                    disabled
                    variant="applicationsClosed"
                    color="theme_blue"
                >
                    Applications not open
                </Button>
            )
        }
        case EventStatuses.REGISTRATION_OPEN.id: {
            if (!hasJudge || !hasGlobalJudge) {
                if (isAuthenticated) {
                    if (hasRegistration) {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                push(`${match.url}/register`),
                                            )
                                        }
                                        variant="applicationsClosed"
                                        color="theme_blue"
                                    >
                                        {t('Edit_registration_')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() =>
                                            dispatch(
                                                push(
                                                    `/dashboard/${event.slug}`,
                                                ),
                                            )
                                        }
                                        variant="applicationsClosed"
                                        color="theme_blue"
                                    >
                                        {t('Event_dashboard_')}
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    } else {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(push(`${match.url}/register`))
                                }
                                variant="applicationsClosed"
                                color="theme_blue"
                            >
                                {t('Register_now_')}
                            </Button>
                        )
                    }
                } else {
                    return (
                        <Button
                            onClick={() =>
                                dispatch(
                                    push(`/login`, {
                                        nextRoute: `${match.url}/register`,
                                    }),
                                )
                            }
                            variant="applicationsClosed"
                            color="theme_blue"
                        >
                            {t('Log_in_register_')}
                        </Button>
                    )
                }
            } else {
                return (
                    <Typography variant="subtitle1" align="center">
                        Registration is ongoing. As a jury member you can't
                        register for the event.
                    </Typography>
                )
            }
        }
        default: {
            if (!hasGlobalJudge) {
                if (isAuthenticated) {
                    if (hasRegistration) {
                        return (
                            <Button
                                onClick={() =>
                                    dispatch(push(`/dashboard/${event.slug}`))
                                }
                                variant="applicationsClosed"
                                color="theme_blue"
                            >
                                {t('Event_dashboard_')}
                            </Button>
                        )
                    } else {
                        return (
                            <Typography variant="subtitle1">
                                {t('Period_ended_')}
                            </Typography>
                        )
                    }
                } else {
                    return (
                        <Button
                            onClick={() =>
                                dispatch(
                                    push('/login', { nextRoute: match.url }),
                                )
                            }
                            variant="applicationsClosed"
                            color="theme_blue"
                        >
                            {t('Log_in_')}
                        </Button>
                    )
                }
            } else {
                return (
                    <Button
                        onClick={() =>
                            dispatch(push(`/finalist/${event.slug}`))
                        }
                        variant="applicationsClosed"
                        color="theme_blue"
                    >
                        Finalist
                    </Button>
                )
            }
        }
    }
}
