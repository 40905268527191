import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import PageWrapper from 'components/layouts/PageWrapper'
import { Box, Grid, Typography } from '@material-ui/core'
import Container from 'components/generic/Container'

const useStyles = makeStyles(theme => ({
    bottom: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    cardImage: {
        height: '246px',
        width: '300px',
        borderRadius: '15px',
    },
}))

export default ({ loading = false }) => {
    const classes = useStyles()
    return (
        <Container center>
            <Grid
                container
                spacing={6}
                direction="row"
                alignItems="stretch"
                justify="center"
            >
                <Grid item xs={12} md={6} lg={4}>
                    <a href="https://docs.google.com/presentation/d/1G9KipRS-WgtZvD6ldZiEajOGL3YDQJODN2chNhWmYwA/edit#slide=id.g22f9a4e87df_2_0">
                        <Box marginBottom="30px">
                            <img
                                src={require('assets/images/hackweek2023.png')}
                                className={classes.cardImage}
                            />
                            <div className={classes.bottom}>
                                <Box width="100%" height="4em" margin="0">
                                    <Typography variant="h6">
                                        Hackweek 2023 winner:
                                    </Typography>
                                    <Typography variant="h6">EMEA</Typography>
                                </Box>
                            </div>
                        </Box>
                    </a>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <a href="https://docs.google.com/presentation/d/1nYJ8KiVbR1d_IO-nu0aUuyakZSnqd78IaxTGYo2pfw0/edit#slide=id.g2184a2bf25c_44_21">
                        <Box marginBottom="30px">
                            <img
                                src={require('assets/images/hackweek2023.png')}
                                className={classes.cardImage}
                            />
                            <div className={classes.bottom}>
                                <Box width="100%" height="4em" margin="0">
                                    <Typography variant="h6">
                                        Hackweek 2023 winner:
                                    </Typography>
                                    <Typography variant="h6">APAC</Typography>
                                </Box>
                            </div>
                        </Box>
                    </a>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <a href="https://docs.google.com/presentation/d/1X4n-ReTASIiXyTUErSUbtxLWmL_7dz-y-QfDMayXH1E/edit#slide=id.g2183764198c_31_0">
                        <Box marginBottom="30px">
                            <img
                                src={require('assets/images/hackweek2023.png')}
                                className={classes.cardImage}
                            />
                            <div className={classes.bottom}>
                                <Box width="100%" height="4em" margin="0">
                                    <Typography variant="h6">
                                        Hackweek 2023 winner:
                                    </Typography>
                                    <Typography variant="h6">
                                        Americas
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    </a>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <a href="https://docs.google.com/presentation/d/1GL6Qt1HzIM3PPbMLZdEiAvDgHWgIdnlrAz2R-8gVOWE/edit#slide=id.g219f3aa4892_31_0">
                        <Box marginBottom="30px">
                            <img
                                src={require('assets/images/hackweek2023.png')}
                                className={classes.cardImage}
                            />
                            <div className={classes.bottom}>
                                <Box width="100%" height="4em" margin="0">
                                    <Typography variant="h6">
                                        Hackweek 2023 finalists
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    </a>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <a href="https://docs.google.com/presentation/d/1GL6Qt1HzIM3PPbMLZdEiAvDgHWgIdnlrAz2R-8gVOWE/edit#slide=id.g219f3aa4892_31_0">
                        <Box marginBottom="30px">
                            <img
                                src={require('assets/images/hackweek2021.png')}
                                className={classes.cardImage}
                            />
                            <div className={classes.bottom}>
                                <Box width="100%" height="4em" margin="0">
                                    <Typography variant="h6">
                                        Hackweek 2021
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    </a>
                </Grid>
            </Grid>
        </Container>
    )
}
