const EventTypes = {
    physical: {
        id: 'physical',
        label: 'Physical event',
    },
    online: {
        id: 'online',
        label: 'Online event',
    },
}

module.exports = EventTypes
