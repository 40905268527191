const modules = [
        'DevOps Modernization - Continuous Integration',
        'DevOps Modernization - Continuous Delivery & GitOps',
        'DevOps Modernization - Feature Flags',
        'DevOps Modernization - Infrastructure as Code Management',
        'DevOps Modernization - Chaos Engineering',
        'DevOps Modernization - Service Reliability Management',
        'Developer Experience - Internal Developer Portal',
        'Developer Experience Software Engineering Insights',
        'Developer Experience - Software Engineering Insights',
        'Developer Experience - Code Repository ',
        'Secure Software Delivery - Security Testing Orchestration',
        'Secure Software Delivery - Software Supply Chain Assurance',
        'Cloud Cost Optimization - Cloud Cost Management',
        'TBD',
        'N/A',
]
module.exports = modules
