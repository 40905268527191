const office = [
        'MTV office',
        'SF office',                                                       
        'Belfast office',
        'Belgrade office',
        'Bangalore office',
        'Dallas office',
        'London office',
        'Coworking space',
        'I am a remote employee'        
]
module.exports = office
