export const UPDATE_EVENT = 'dashboard/UPDATE_EVENT'
export const UPDATE_REGISTRATION = 'dashboard/UPDATE_REGISTRATION'
export const UPDATE_TEAM = 'dashboard/UPDATE_TEAM'
export const UPDATE_PROJECTS = 'dashboard/UPDATE_PROJECTS'
export const UPDATE_ANNOTATOR = 'dashboard/UPDATE_ANNOTATOR'
export const UPDATE_PROJECT_SCORES = 'dashboard/UPDATE_PROJECT_SCORES'
export const GET_PROJECTS_BY_RATING = 'dashboard/GET_PROJECTS_BY_RATING'
export const EDIT_REGISTRATION = 'dashboard/EDIT_REGISTRATION'
export const GET_TEAMS = 'dashboard/GET_TEAMS'
export const EDIT_TEAM = 'dashboard/EDIT_TEAM'
export const EDIT_PROFILES = 'dashboard/EDIT_PROFILES'
export const EDIT_ANNOTATOR = 'dashboard/EDIT_ANNOTATOR'
export const GIVE_SCORE = 'dashboard/GIVE_SCORE'
export const GIVE_FEEDBACK = 'dashboard/GIVE_FEEDBACK'
export const GET_SCORES = 'dashboard/GET_SCORES'
export const REMOVE_SCORE = 'dashboard/REMOVE_SCORE'
export const GET_FINALIST_TOP10 = 'dashboard/GET_FINALIST_TOP10'
export const RATE_PROJECT = 'dashboard/RATE_PROJECT'
export const UNRATE_PROJECT = 'dashboard/UNRATE_PROJECT'
export const CLEAR_TEAM = 'dashboard/CLEAR_TEAM'
export const GET_COMMENTS = 'dashboard/GET_COMMENTS'
export const GET_EVENT_STATUS = 'dashboard/GET_EVENT_STATUS'
export const GET_IS_SUBMISSIONS_UPCOMING =
    'dashboard/GET_IS_SUBMISSIONS_UPCOMING'
export const UPDATE_TEAMS = 'dashboard/UPDATE_TEAMS'
export const UPDATE_REGISTRATIONS = 'dashboard/UPDATE_REGISTRATIONS'
