import _axios from 'services/axios'

const ProjectsService = {}

function config(idToken) {
    return {
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
    }
}
ProjectsService.getProjectsByEvent = eventSlug => {
    return _axios.get(`/projects/${eventSlug}`)
}

ProjectsService.getScores = (idToken, projectId) => {
    return _axios.get(`/scores/${projectId}`, config(idToken))
}

ProjectsService.getAllGlobalScores = async idToken => {
    return await _axios.get(
        `/scores/get-all-global-scores/current`,
        config(idToken),
    )
}

ProjectsService.getAllRegionalScores = async idToken => {
    return await _axios.get(
        `/scores/get-all-regional-scores/current`,
        config(idToken),
    )
}

ProjectsService.giveAllScores = (idToken, projectId, data) => {
    return _axios.post(`/scores/give-score/${projectId}`, data, config(idToken))
}

ProjectsService.giveAllGlobalScores = (idToken, projectId, data) => {
    return _axios.post(
        `/scores/give-global-score/${projectId}`,
        data,
        config(idToken),
    )
}

ProjectsService.rateProject = (idToken, projectId) => {
    return _axios.get(`/projects/rate/${projectId}`, config(idToken))
}

ProjectsService.unrateProject = (idToken, projectId) => {
    return _axios.get(`/projects/unrate/${projectId}`, config(idToken))
}

ProjectsService.giveScore = (idToken, projectId, score) => {
    console.log(score)
    // add score as query param
    return _axios.get(
        `/projects/give-score/${projectId}` + `?score=${score}`,
        config(idToken),
    )
}

ProjectsService.giveGlobalScore = (idToken, projectId, score) => {
    console.log(score)
    // add score as query param
    return _axios.get(
        `/projects/give-global-score/${projectId}` + `?score=${score}`,
        config(idToken),
    )
}

ProjectsService.giveFeedback = (idToken, projectId, content) => {
    console.log(content)
    // add content as query param
    return _axios.get(
        `/feedbacks/give-feedback/${projectId}` + `?content=${content}`,
        config(idToken),
    )
}

ProjectsService.getTop10FinalistProjects = slug => {
    return _axios.get(`/projects/finalist/top10/${slug}`)
}

ProjectsService.removeScore = (idToken, projectId) => {
    return _axios.get(`/projects/remove-score/${projectId}`, config(idToken))
}

ProjectsService.makeWinner = (idToken, projectId) => {
    return _axios.get(`/projects/make-winner/${projectId}`, config(idToken))
}

ProjectsService.getComments = projectId => {
    return _axios.get(`/comments/${projectId}`)
}

ProjectsService.postComment = (idToken, projectId, data) => {
    return _axios.post(`/comments/${projectId}`, data, config(idToken))
}

ProjectsService.getFinalistProjects = slug => {
    return _axios.get(`/projects/finalist/${slug}`)
}

ProjectsService.getProjectRating = projectId => {
    return _axios.get(`/projects/rating/${projectId}`)
}

ProjectsService.getPublicProjectById = projectId => {
    return _axios.get(`/projects/id/${projectId}`)
}

ProjectsService.getProjectsForEventAndTeam = (idToken, eventSlug) => {
    return _axios.get(`/projects/${eventSlug}/team`, config(idToken))
}

ProjectsService.createProjectForEventAndTeam = (idToken, eventSlug, data) => {
    return _axios.post(`/projects/${eventSlug}/team`, { data }, config(idToken))
}

ProjectsService.updateProjectForEventAndTeam = (idToken, eventSlug, data) => {
    return _axios.patch(
        `/projects/${eventSlug}/team`,
        { data },
        config(idToken),
    )
}

ProjectsService.getAllProjectsAsOrganiser = (idToken, eventSlug) => {
    return _axios.get(`/projects/${eventSlug}/admin`, config(idToken))
}

ProjectsService.generateChallengeLink = (idToken, eventSlug, challengeSlug) => {
    return _axios.get(
        `/projects/${eventSlug}/admin/challenge/${challengeSlug}/link`,
        config(idToken),
    )
}

ProjectsService.generateTrackLink = (idToken, eventSlug, trackSlug) => {
    console.log('trackSlug :>> ', trackSlug)
    return _axios.get(
        `/projects/${eventSlug}/admin/track/${trackSlug}/link`,
        config(idToken),
    )
}

ProjectsService.getChallengeProjectsWithToken = (eventSlug, token) => {
    return _axios.get(`/projects/${eventSlug}/challenge/${token}`)
}

ProjectsService.getTrackProjectsWithToken = (eventSlug, token) => {
    const projects = _axios.get(`/projects/${eventSlug}/tracks/${token}`)
    console.log('projects :>> ', projects)
    return _axios.get(`/projects/${eventSlug}/tracks/${token}`)
}

ProjectsService.validateToken = (eventSlug, token) => {
    return _axios.get(`/projects/${eventSlug}/token/${token}/validate`)
}

ProjectsService.getCommits = projectId => {
    const project = _axios.get(`/projects/id/${projectId}`)
    return project
}

export default ProjectsService
