const yup = require('yup')
const EventTypes = require('./event-types')
const ProjectStatuses = require('./project-statuses')

/** The user editable fields and their validation rules for a Project */
// TODO: Add tracks and challenges
const FeedbackSchema = {
    content: yup.string().required().max(1000).label('Content'),
}

const buildProjectSchema = () => {
    const schema = { ...FeedbackSchema }

    // if (event.tracksEnabled) {
    //     schema.track = yup
    //         .string()
    //         .oneOf(event.tracks.map(track => track.slug))
    //         .required()
    //         .label('Track')
    // }

    // if (event.challengesEnabled) {
    //     schema.challenges = yup
    //         .array()
    //         .of(
    //             yup
    //                 .string()
    //                 .oneOf(event.challenges.map(challenge => challenge.slug)),
    //         )
    //         .max(5)
    //         .ensure()
    //         .label('Challenges')
    // }

    // if (event.eventType === EventTypes.physical.id) {
    //     schema.location = yup
    //         .string()
    //         .max(100)
    //         .required()
    //         .label('Table location')
    // }

    return schema
}

module.exports = buildProjectSchema
