const Countries = require('./countries')
const Genders = require('./genders')
const Expertise = require('./expertise')
const HackModule = require('./modules')
const Office = require('./office')
const CollaborationInOffice = require('./collaborationInOffice')
const Categories = require('./categories')
const Division = require('./division')
const Role = require('./role')
const Industries = require('./industries')
const Languages = require('./languages')
const Roles = require('./roles')
const Skills = require('./skills')
const Themes = require('./themes')
const RegistrationStatuses = require('./registration-statuses')
const Misc = require('./misc')
const Currencies = require('./currencies')
const Timezones = require('../data/timezones')

const currentYear = new Date().getFullYear()

const SelectOptions = {
    COUNTRIES: Countries.asArrayOfName.map(country => ({
        label: country,
        value: country,
    })),
    COUNTRY_CODES: Countries.asArray.map(country => ({
        label: `${country.en_short_name} (${country.phone_code})`,
        value: country.phone_code,
    })),
    CURRENCIES: Currencies.asArray.map(currency => ({
        label: currency.name,
        value: currency.code,
    })),
    NATIONALITIES: Countries.asArrayOfNationalities.map(nationality => ({
        label: nationality,
        value: nationality,
    })),
    NUM_HACKATHONS: Misc.numHackathonOptions.asArray,
    DIETARY_RESTRICTIONS: Misc.dietaryRestrictions.map(item => ({
        label: item,
        value: item,
    })),
    GENDERS: Genders.map(gender => ({
        label: gender,
        value: gender,
    })),
    EXPERTISE: Expertise.map(expertise => ({
        label: expertise,
        value: expertise,
    })),
    COLLABORATIONINOFFICE: CollaborationInOffice.map(collaborationInOffice => ({
        label: collaborationInOffice,
        value: collaborationInOffice,
    })),
    DIVISION: Division.map(division => ({
        label: division,
        value: division,
    })),
    Role: Role.map(role => ({
        label: role,
        value: role,
    })),
    OFFICE: Office.map(office => ({
        label: office,
        value: office,
    })),
    HACKMODULES: HackModule.map(modules => ({
        label: modules,
        value: modules,
    })),
    CATEGORIES: Categories.map(categories => ({
        label: categories,
        value: categories,
    })),
    INDUSTRIES: Industries.industries.map(industry => ({
        label: industry,
        value: industry,
    })),
    LANGUAGES: Languages.asArrayOfNames.map(language => ({
        label: language,
        value: language,
    })),
    ROLES: Roles.items.map(role => ({
        label: role,
        value: role,
    })),
    SKILLS: Skills.items.map(skill => ({
        label: skill,
        value: skill,
    })),
    SKILLS_NO_ABSTRACT: Skills.itemsWithoutAbstract.map(skill => ({
        label: skill,
        value: skill,
    })),
    THEMES: Themes.themes.map(theme => ({
        label: theme,
        value: theme,
    })),
    T_SHIRT_SIZES: Misc.tShirtSizes.map(size => ({
        label: size,
        value: size,
    })),
    TIMEZONES: Timezones.map(tz => ({
        label: tz,
        value: tz,
    })),
    REGIONS: ['America', 'EMCA', 'Asia'].map(status => ({
        label: status,
        value: status,
    })),
    STATUSES: RegistrationStatuses.asArray.map(status => ({
        label: status.label,
        value: status.id,
    })),
    DAYS: Array.apply(null, Array(31)).map((val, index) => ({
        label: index + 1,
        value: index + 1,
    })),
    MONTHS: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ].map((month, index) => ({
        label: month,
        value: index + 1,
    })),
    YEARS: Array.apply(null, Array(120)).map((val, index) => ({
        value: currentYear - index,
        label: currentYear - index,
    })),
    YEARS_FUTURE: Array.apply(null, Array(120)).map((val, index) => ({
        value: currentYear + 15 - index,
        label: currentYear + 15 - index,
    })),
}

module.exports = SelectOptions
