import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    star: {
        fill: 'none',
        stroke: '#000',
        strokeWidth: '2px',
    },
}))

const StarSvg = ({ on }) => {
    const classes = useStyles()
    // if on is true, then fill the star
    // else, leave it empty
    if (on) {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#f0970a"
                className="bi bi-star-fill"
                viewBox="0 0 16 16"
            >
                {' '}
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />{' '}
            </svg>
        )
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="white"
            className="bi bi-star-fill"
            viewBox="0 0 16 16"
        >
            {' '}
            <path
                stroke="black"
                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
            />{' '}
        </svg>
    )
}

export default StarSvg
