import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as DashboardActions from 'redux/dashboard/actions'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import LoadingSpinner from './loadingCircle'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'

// import { CommentSection } from 'react-comments-section'
// import 'react-comments-section/dist/index.css'

const Comments = ({ data, user, projectId }) => {
    const dispatch = useDispatch()
    const [commentText, setCommentText] = React.useState('')
    const comments = useSelector(DashboardSelectors.comments)
    const [loading, setLoading] = React.useState(false)
    const [replyText, setReplyText] = React.useState('')
    const [reply, setReply] = React.useState(null)
    const [loadingReply, setLoadingReply] = React.useState(false)
    const [replyReplies, setReplyReplies] = React.useState(null)
    const [loadingReplyReply, setLoadingReplyReply] = React.useState(false)
    const [replyReplyText, setReplyReplyText] = React.useState('')
    const [replyReply, setReplyReply] = React.useState(null)

    useEffect(() => {
        TimeAgo.addDefaultLocale(en)
        TimeAgo.addLocale(ru)
        console.log('comments', comments)
    }, [comments])

    console.log('comments', data)

    // console.log(user)
    return (
        <div className="flex flex-col items-center justify-center">
            <div className="w-full">
                <label htmlFor="comment text-left text-xl">
                    Leave a Comment
                </label>
                <div className="flex">
                    <textarea
                        className="border-2 border-black rounded-md rounded-tr-none rounded-br-none p-2 w-full"
                        id="comment"
                        value={commentText}
                        onChange={e => setCommentText(e.target.value)}
                        name="comment"
                        placeholder="Leave a comment"
                    ></textarea>
                    <button
                        onClick={() => {
                            setLoading(true)
                            dispatch(
                                DashboardActions.postComment(
                                    projectId,
                                    projectId,
                                    {
                                        text: commentText,
                                        userId: user.sub,
                                        fullName: user.name,
                                        avatarUrl: user.picture,
                                        isReply: false,
                                    },
                                ),
                            )
                            dispatch(DashboardActions.getComments(projectId))
                            setCommentText('')
                            setLoading(false)
                            setReply(null)
                            setReplyText('')
                        }}
                        className="text-white rounded-md rounded-tl-none rounded-bl-none bg-black px-4 py-4"
                    >
                        {loading ? <LoadingSpinner /> : 'Comment'}
                    </button>
                </div>
            </div>

            {data ? (
                <div className="p-2 w-full">
                    {data.map((comment, index) => {
                        return (
                            <div>
                                <div
                                    key={index}
                                    className="flex flex-row justify-between py-4"
                                >
                                    <img
                                        className="w-10 mr-2 h-10 rounded-full"
                                        src={comment.avatarUrl}
                                        alt="user"
                                    />
                                    <div className="flex-grow flex items-center">
                                        <div className="flex flex-col items-start">
                                            <p className="font-bold text-start">
                                                {comment.text}
                                            </p>
                                            <button
                                                className=""
                                                onClick={() => {
                                                    setReplyText('')
                                                    setReply(index)
                                                }}
                                            >
                                                <span className="text-blue-500 text-sm">
                                                    Replies
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex flex-col">
                                        <div className="flex flex-col justify-end items-end">
                                            <p className="text-gray-500 ml-2">
                                                <ReactTimeAgo
                                                    date={comment.createdAt}
                                                    locale="en-US"
                                                />
                                            </p>
                                            <p>{comment.fullName}</p>
                                        </div>
                                    </div>
                                </div>
                                {reply === index && (
                                    <div className="pl-12 rounded-sm py-4 pr-2 bg-gray-200">
                                        <div className="flex">
                                            <textarea
                                                className="border-2 border-black rounded-md rounded-tr-none rounded-br-none p-2 w-full"
                                                id="comment"
                                                value={replyText}
                                                onChange={e =>
                                                    setReplyText(e.target.value)
                                                }
                                                name="comment"
                                                placeholder="Send a reply"
                                            ></textarea>
                                            <button
                                                onClick={() => {
                                                    setLoadingReply(true)
                                                    dispatch(
                                                        DashboardActions.postComment(
                                                            projectId,
                                                            comment._id,
                                                            {
                                                                text: replyText,
                                                                userId: user.sub,
                                                                fullName:
                                                                    user.name,
                                                                avatarUrl:
                                                                    user.picture,
                                                                isReply: true,
                                                            },
                                                        ),
                                                    )
                                                    dispatch(
                                                        DashboardActions.getComments(
                                                            projectId,
                                                        ),
                                                    )
                                                    setReplyText('')
                                                    setLoadingReply(false)
                                                }}
                                                className="text-white rounded-md rounded-tl-none rounded-bl-none bg-black px-4 py-4"
                                            >
                                                {loadingReply ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    'Reply'
                                                )}
                                            </button>
                                        </div>
                                        <div>
                                            {comment.replies.map(
                                                (reply, index) => {
                                                    return (
                                                        <div>
                                                            <div
                                                                key={index}
                                                                className="flex flex-row justify-between py-4"
                                                            >
                                                                <img
                                                                    className="w-10 mr-2 h-10 rounded-full"
                                                                    src={
                                                                        reply.avatarUrl
                                                                    }
                                                                    alt="user"
                                                                />
                                                                <div className="flex-grow flex items-center">
                                                                    <div className="flex flex-col items-start">
                                                                        <p className="font-bold text-start">
                                                                            {
                                                                                reply.text
                                                                            }
                                                                        </p>
                                                                        <button
                                                                            className=""
                                                                            onClick={() =>
                                                                                setReplyReplies(
                                                                                    index,
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="text-blue-500 text-sm">
                                                                                Reply
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <div className="flex flex-col justify-end items-end">
                                                                        <p className="text-gray-500 ml-2">
                                                                            <ReactTimeAgo
                                                                                date={
                                                                                    reply.createdAt
                                                                                }
                                                                                locale="en-US"
                                                                            />
                                                                        </p>
                                                                        <p>
                                                                            {
                                                                                reply.fullName
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {replyReplies ===
                                                                index && (
                                                                <div className="pl-12 rounded-sm py-4 pr-2 bg-gray-200">
                                                                    <div className="flex">
                                                                        <textarea
                                                                            className="border-2 border-black rounded-md rounded-tr-none rounded-br-none p-2 w-full"
                                                                            id="comment"
                                                                            value={
                                                                                replyReplyText
                                                                            }
                                                                            onChange={e =>
                                                                                setReplyReplyText(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                )
                                                                            }
                                                                            name="comment"
                                                                            placeholder="Reply..."
                                                                        ></textarea>
                                                                        <button
                                                                            onClick={() => {
                                                                                setLoadingReplyReply(
                                                                                    true,
                                                                                )
                                                                                dispatch(
                                                                                    DashboardActions.postComment(
                                                                                        projectId,
                                                                                        comment._id,
                                                                                        {
                                                                                            text: `@${reply.fullName} ${replyReplyText}`,
                                                                                            userId: user.sub,
                                                                                            fullName:
                                                                                                user.name,
                                                                                            avatarUrl:
                                                                                                user.picture,
                                                                                            isReply: true,
                                                                                        },
                                                                                    ),
                                                                                )
                                                                                dispatch(
                                                                                    DashboardActions.getComments(
                                                                                        projectId,
                                                                                    ),
                                                                                )
                                                                                setReplyReplyText(
                                                                                    '',
                                                                                )
                                                                                setReplyReplies(
                                                                                    null,
                                                                                )
                                                                                setLoadingReplyReply(
                                                                                    false,
                                                                                )
                                                                            }}
                                                                            className="text-white rounded-md rounded-tl-none rounded-bl-none bg-black px-4 py-4"
                                                                        >
                                                                            {loadingReplyReply ? (
                                                                                <LoadingSpinner />
                                                                            ) : (
                                                                                'Reply'
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div>
                    <LoadingSpinner />
                </div>
            )}
        </div>
    )
}

export default Comments
