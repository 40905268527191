import React from 'react'
import clsx from 'clsx'
import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    filterItem: {
        backgroundColor: 'transparent',
        color: '#0278D5',
        padding: theme.spacing(1),
        border: '1px solid #0278D5',
        borderRadius: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 60px',
        margin: '12px',
        cursor: 'pointer',
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: '#0278d5c7',
            border: '1px solid #0278d5c7',
            color: 'white',
        },
    },
    filterItemActive: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        opacity: 1,
        '&:hover': {
            backgroundColor: '#0278d5c7',
            border: '1px solid #0278d5c7',
            color: 'white',
        },
    },
    filterItemText: {
        fontWeight: 'normal',
        fontSize: '20px',
        textAlign: 'center',
        color: 'inherit',
        textTransform: 'none',
        lineHeight: 'normal',
    },
}))

const Filters = ({ event, active = 'by-track', onChange }) => {
    const classes = useStyles()
    return (
        <Grid container justify="center" spacing={3}>
            {/* {event.tracksEnabled && event.tracks && (
                <Box
                    onClick={() => onChange('by-track')}
                    className={clsx(classes.filterItem, {
                        [classes.filterItemActive]: active === 'by-track',
                    })}
                >
                    <Typography
                        className={classes.filterItemText}
                        variant="button"
                    >
                        By category
                    </Typography>
                </Box>
            )} */}
            {event.challengesEnabled && event.challenges && (
                <Box>
                    <Box
                        onClick={() => onChange('by-challenge')}
                        className={clsx(classes.filterItem, {
                            [classes.filterItemActive]:
                                active === 'by-challenge',
                        })}
                    >
                        <Typography
                            className={classes.filterItemText}
                            variant="button"
                        >
                            By challenge
                        </Typography>
                    </Box>
                </Box>
            )}
            {/* <Box
                onClick={() => onChange('')}
                className={clsx(classes.filterItem, {
                    [classes.filterItemActive]: active === '',
                })}
            >
                <Typography className={classes.filterItemText} variant="button">
                    All projects
                </Typography>
            </Box> */}
            {/* <switch options */}

            {/* <Grid item xs={12} md={6}>
                <Box
                    onClick={() => onChange('search')}
                    className={clsx(classes.filterItem, {
                        [classes.filterItemActive]: active === 'search',
                    })}
                >
                    <Typography
                        className={classes.filterItemText}
                        variant="button"
                    >
                        Search projects
                    </Typography>
                </Box>
                {active === 'search' ? (
                    <TextInput label="Search projects" autoFocus="true" />
                ) : null}
            </Grid> */}
        </Grid>
    )
}

export default Filters
