import React, { useMemo, useRef, useContext, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { RegistrationFields } from '@hackjunction/shared'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid } from '@material-ui/core'
import { Formik, FastField, useFormik } from 'formik'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import * as UserSelectors from 'redux/user/selectors'
import * as AuthSelectors from 'redux/auth/selectors'
import RegistrationQuestion from '../RegistrationQuestion'
import RegistrationBottomBar from '../RegistrationBottomBar'
import EventDetailContext from '../../context'
import UserProfilesService from 'services/userProfiles'
import * as UserActions from 'redux/user/actions'
import * as AuthActions from 'redux/auth/actions'
import * as SnackbarActions from 'redux/snackbar/actions'

const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    question: {
        backgroundColor: 'white',
        marginTop: '1px',
        padding: theme.spacing(3),
        transition: 'all 0.2s ease',
        '&:focus-within': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    },
    questionInner: {
        opacity: 0.6,
        '&:focus-within': {
            opacity: 1,
        },
    },
    textField: {
        '& input': {
            color: 'black',
        },
    },
}))

export default props => {
    const dispatch = useDispatch()
    const { fields, onNext, nextLabel, prevLabel, onPrev, data, isActive } =
        props
    const { event, registration } = useContext(EventDetailContext)
    const userProfile = useSelector(UserSelectors.userProfile)
    const idTokenData = useSelector(AuthSelectors.idTokenData)
    const idToken = useSelector(AuthSelectors.getIdToken)
    const classes = useStyles({ isActive })
    const mainRef = useRef(null)

    const handleSubmit = useCallback(
        async (data, actions) => {
            console.log(data)
            actions.setSubmitting(true)
            try {
                const profile = await UserProfilesService.updateUserProfile(
                    data,
                    idToken,
                )
                dispatch(UserActions.setUserProfile(profile))
                dispatch(AuthActions.pushNextRoute())
            } catch (err) {
                dispatch(
                    SnackbarActions.error(
                        'Something went wrong... Please try again',
                    ),
                )
            }
            actions.setSubmitting(false)
            return
        },
        [dispatch, idToken],
    )

    const formik = useFormik({
        initialValues: {
            firstName: idTokenData?.given_name ?? '',
            lastName: idTokenData?.family_name ?? '',
            email: idTokenData?.email ?? '',
            avatar: idTokenData?.picture ?? '',
            hackRole: idToken?.roles,
            accepted: false,
        },
        onSubmit: handleSubmit,
        validationSchema: yup.object().shape({
            firstName: yup
                .string()
                .min(1)
                .max(100)
                .required()
                .label('First name'),
            lastName: yup
                .string()
                .min(1)
                .max(100)
                .required()
                .label('Last name'),
            email: yup.string().email().required().label('Email'),
            location: yup.string().required().label('Location'),
            hackRole: yup
                .string()
                .required()
                .label('hackRole')
                .default(idToken?.roles),
            accepted: yup.bool().oneOf([true]),
        }),
    })

    // const [formValues, setFormValues] = React.useState(defaultFormValues)

    const { validationSchema, initialValues } = useMemo(() => {
        return fields.reduce(
            (result, field) => {
                const fieldParams = RegistrationFields.getField(field.fieldName)

                if (fieldParams) {
                    result.validationSchema[field.fieldName] =
                        fieldParams.validationSchema(field.require, event)
                    if (
                        registration &&
                        registration.answers &&
                        registration.answers[field.fieldName]
                    ) {
                        result.initialValues[field.fieldName] =
                            registration.answers[field.fieldName]
                    } else {
                        result.initialValues[field.fieldName] =
                            fieldParams.default(userProfile, idTokenData)
                    }
                }
                if (data.hasOwnProperty(field.fieldName)) {
                    result.initialValues[field.fieldName] =
                        data[field.fieldName]
                }
                return result
            },
            {
                validationSchema: {},
                initialValues: {},
            },
        )
    }, [fields, data, event, registration, userProfile, idTokenData])
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={props => {
                return yup.lazy(values => {
                    return yup.object().shape(validationSchema)
                })
            }}
            onSubmit={(values, actions) => {
                onNext(values)
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                dirty,
            }) => (
                <>
                    <Box display="flex" flexDirection="column" ref={mainRef}>
                        <Box p={2} className={classes.wrapper}>
                            <Grid container spacing={0}>
                                {fields.map((field, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            key={field.fieldName}
                                            className={classes.question}
                                        >
                                            <div
                                                className={
                                                    classes.questionInner
                                                }
                                            >
                                                <FastField
                                                    autoFocus={index === 0}
                                                    name={field.fieldName}
                                                    component={
                                                        RegistrationQuestion
                                                    }
                                                    config={field.fieldConfig}
                                                />
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </Box>
                    {ReactDOM.createPortal(
                        <RegistrationBottomBar
                            prevLabel={prevLabel}
                            onPrev={onPrev}
                            nextLabel={nextLabel}
                            onNext={handleSubmit}
                            errors={errors}
                            dirty={dirty}
                        />,
                        document.body,
                    )}
                </>
            )}
        </Formik>
    )
}
