import React, {
    useEffect,
    useContext,
    useMemo,
    useState,
    useCallback,
} from 'react'
import { Grid, Box, Typography, makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import EventHeroImage from 'components/events/EventHeroImage'
import Markdown from 'components/generic/Markdown'
import AnalyticsService from 'services/analytics'
import EventsService from 'services/events'
import ProjectsService from 'services/projects'
import { sortBy } from 'lodash-es'
import EventTimeline from './EventTimeline'
import BannerCarousel from 'components/generic/BannerCarousel'
import EventInformation from './EventInformation'
import StaggeredList from 'components/animated/StaggeredList'
import StaggeredListItem from 'components/animated/StaggeredListItem'
import FadeInWrapper from 'components/animated/FadeInWrapper'
import Container from 'components/generic/Container'
import { Helmet } from 'react-helmet'
import EventDetailContext from '../context'
import EventButtons from './EventButtons'
import Winner from './EventInformation/winner'
import ProjectsPreview from 'pages/_projects/slug/default/ProjectsPreview'
// import Filters from './Filters'
import ProjectsGrid from 'components/projects/ProjectsGrid'
import Filters from 'pages/_projects/slug/default/Filters'
import Button from 'components/generic/Button'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons/'
import ExternalLink from 'components/generic/ExternalLink'

const useStyles = makeStyles({
    header: {
        background: props => props.headerBackgroundColor,
        color: props => props.headerTextColor,

        '& button:not(disabled)': {
            color: props => props.headerBackgroundColor,
            background: '#0278D5',

            '&:hover': {
                background: props => props.accentColor,
            },
        },
    },
    cta: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3rem 2rem',
        margin: '20px 0 0 0',
        '& button': {
            margin: 0,
        },
    },
    body: {
        background: props => props.bodyBackgroundColor,
        '& a>p': {
            color: props => props.linkColor,
        },
    },
    details: {
        background: props => props.detailsBackgroundColor,

        '& button': {
            marginBottom: '30px',
        },
    },
    sidebar: {
        background: props => props.sidebarBackgroundColor,
    },
})

export default () => {
    const [isShowMore, setIsShowMore] = useState(true)

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore)
    }
    const dispatch = useDispatch()
    // const [event, setEvent] = useState()
    const [projects, setProjects] = useState([])
    const [error, setError] = useState(false)
    const { slug, event, registration } = useContext(EventDetailContext)
    const [loading, setLoading] = useState(true)
    const [activeFilter, setActiveFilter] = useState('')
    console.log(useContext(EventDetailContext))
    // TODO use Graphql here to get event
    // useEventPreview(eventId) but with slug
    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            const [event, projects] = await Promise.all([
                EventsService.getPublicEventBySlug(slug),
                ProjectsService.getProjectsByEvent(slug),
            ])
            console.log(event)
            if (!event) {
                dispatch(push('/'))
            }
            setProjects(projects)
            // setEvent(event)
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }, [dispatch, slug])

    useEffect(() => {
        fetchData()
    }, [fetchData])
    const classes = useStyles(event.theme)
    const keywords = event.name.split(' ').join(', ')
    console.log('KEYWORDS', keywords)
    console.log('HELMET', Helmet.peek())

    useEffect(() => {
        if (slug) {
            AnalyticsService.events.VIEW_EVENT(slug)
        }
    }, [slug])
    const coverImage = () => {
        if (event.coverImage !== null) return event.coverImage.url
        else return 'Harness Hackweek'
    }

    const eventDescription = () => {
        return `${event.name} is coming up! If you're interested in joining the coolest hackathon on the planet just head straight to ...`
    }

    const { byChallenge, byTrack } = useMemo(() => {
        return projects.reduce(
            (res, project) => {
                if (project.track) {
                    if (res.byTrack.hasOwnProperty(project.track)) {
                        res.byTrack[project.track].push(project)
                    } else {
                        res.byTrack[project.track] = [project]
                    }
                }

                if (project.challenges) {
                    project.challenges.forEach(challenge => {
                        if (res.byChallenge.hasOwnProperty(challenge)) {
                            res.byChallenge[challenge].push(project)
                        } else {
                            res.byChallenge[challenge] = [project]
                        }
                    })
                }

                return res
            },
            {
                byChallenge: {},
                byTrack: {},
            },
        )
    }, [projects])

    const renderTrackPreviews = () => {
        if (!event || !event.tracks) return null
        if (projects.length === 0)
            return (
                <Container>
                    <Box mt={3} textAlign="center" mb={3}>
                        <Typography variant="body1">
                            All the projects that are submitted by the
                            participants will be displayed in this section of
                            the page.
                        </Typography>
                    </Box>
                </Container>
            )
        return event.tracks.map(track => {
            const items = byTrack[track.slug]
            if (!items) return null
            const sorted = sortBy(items, item => {
                return -1 * item.description.length
            })
            return (
                <ProjectsPreview
                    key={track.slug}
                    projects={sorted.slice(0, 3)}
                    count={items.length}
                    event={event}
                    label={track.name}
                    moreLink={`/projects/${event.slug}/by-track/${track.slug}`}
                />
            )
        })
    }

    const renderChallengePreviews = () => {
        if (!event || !event.challenges) return null
        if (projects.length === 0)
            return (
                <Container>
                    <Box mt={3} textAlign="center">
                        <Typography variant="body1">
                            All the projects that are submitted by the
                            participants will be displayed in this section of
                            the page.
                        </Typography>
                    </Box>
                </Container>
            )
        return event.challenges.map(challenge => {
            const items = byChallenge[challenge.slug]
            if (!items) return null
            const sorted = sortBy(items, item => {
                return -1 * item.description.length
            })
            return (
                <ProjectsPreview
                    key={challenge.slug}
                    projects={sorted.slice(0, 3)}
                    count={items.length}
                    event={event}
                    label={challenge.name}
                    subheading={`By ${challenge.partner}`}
                    moreLink={`/projects/${event.slug}/by-challenge/${challenge.slug}`}
                />
            )
        })
    }

    const renderProjectGrid = () => {
        console.log(projects)
        if (!event) return null
        if (projects.length === 0)
            return (
                <Container>
                    <Box mt={3} textAlign="center">
                        <Typography variant="body1">
                            All the projects that are submitted by the
                            participants will be displayed in this section of
                            the page.
                        </Typography>
                    </Box>
                </Container>
            )
        return (
            <Container>
                <ProjectsGrid
                    sortField={null}
                    projects={projects}
                    event={event}
                    onSelect={onProjectSelected}
                />
            </Container>
        )
    }

    const renderContent = () => {
        console.log('activeFilter :>> ', activeFilter)

        switch (activeFilter) {
            case 'by-track':
                return renderTrackPreviews()
            case 'by-challenge':
                return renderChallengePreviews()
            default:
                return renderProjectGrid()
        }
    }

    const onProjectSelected = useCallback(
        project => {
            dispatch(push(`/projects/${event.slug}/view/${project._id}`))
        },
        [dispatch, event.slug],
    )

    console.log(event)
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{event.name}</title>
                <meta name="keywords" content={keywords} />
                <meta name="title" content={event.name} />
                <meta property="og:title" content={event.name} />
                <meta name="twitter:title" content={event.name} />
                <meta
                    name="description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />
                <meta
                    property="og:description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />
                <meta
                    name="twitter:description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />

                <meta name="og:type" content="website" />
                <meta property="og:image" content={coverImage()} />
                <meta name="twitter:image" content={coverImage()} />
            </Helmet>
            {/* <BannerCarousel /> */}
            <EventHeroImage
                event={event}
                onBack={() => dispatch(push('/'))}
                backgroundColor={event.theme.headerBackgroundColor}
            />
            <FadeInWrapper>
                <StaggeredList>
                    <Box className={classes.header}>
                        <Container>
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className={classes.header}
                                >
                                    <Box mt={3} />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    className={classes.header}
                                >
                                    <Box mt={3} />

                                    <Typography variant="h2">
                                        {event.name}
                                    </Typography>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                    >
                                        <EventInformation
                                            registration={registration}
                                            event={event}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    <Box className={classes.body}>
                        <Container>
                            {console.log(event)}
                            {event?._eventStatus === 'FINISHED' ? (
                                <Box justifyContent="center" display="flex">
                                    <Winner event={event} />
                                </Box>
                            ) : (
                                <></>
                            )}
                            <Box mt={5} />
                            <Grid container spacing={5} wrap="wrap-reverse">
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    className={classes.sidebar}
                                >
                                    <Box mt={3} />
                                    <StaggeredListItem>
                                        <Box mt={3} />
                                        <EventTimeline
                                            event={event}
                                            accentColor={
                                                event.theme.accentColor
                                            }
                                            textColor={
                                                event.theme.sidebarTextColor
                                            }
                                        />
                                    </StaggeredListItem>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    className={classes.details}
                                >
                                    {/* <Box mt={3} /> */}
                                    <>
                                        <Button
                                            onClick={toggleReadMoreLess}
                                            variant="containedGrey"
                                            color="theme_blue"
                                        >
                                            {isShowMore ? (
                                                <div>
                                                    <KeyboardArrowDown />
                                                    See Less
                                                </div>
                                            ) : (
                                                <div>
                                                    <KeyboardArrowUp />
                                                    About this Project
                                                </div>
                                            )}
                                        </Button>
                                        <StaggeredListItem>
                                            {isShowMore && (
                                                <Markdown
                                                    source={event?.description}
                                                />
                                            )}
                                        </StaggeredListItem>
                                        <Typography variant="body2">
                                            As a reminder, for this Hackathon
                                            the use of Harness or customer data
                                            is out of scope. You should look to
                                            build your POC with public data, and
                                            not with anything that requires a
                                            Harness login to access. If your POC
                                            requires internal data, you must
                                            contact Security and Legal to work
                                            through the use case, and anonymize
                                            or redact any sensitive information.
                                            More information can be found{' '}
                                            <ExternalLink href="https://docs.google.com/document/d/1eH12M9GEfvl8blOPUnS1qHMAHiPu0SYG4SJvoxp3N_o/edit?pli=1#heading=h.owx38ytcgem9">
                                                here
                                            </ExternalLink>
                                            .
                                        </Typography>
                                    </>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    {/* <Box className={`${classes.header} ${classes.cta}`}>
                        <EventButtons
                            event={event}
                            registration={registration}
                        />
                    </Box> */}
                </StaggeredList>
            </FadeInWrapper>
            <Box mt={5} />
            {console.log(event)}
            {event?.galleryOpen && (
                <>
                    <Container>
                        <Filters
                            event={event}
                            active={activeFilter}
                            onChange={setActiveFilter}
                        />
                    </Container>
                    {renderContent()}
                </>
            )}
            <Box mt={5} />
        </>
    )
}
