module.exports = {
    isEmail: value => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(value).toLowerCase())
    },
    concatAndCapitalize(variableName, location) {
        const locationalias = {
            EMEA: 'emca',
            Americas: '',
            India: 'asia',
        }

        // Capitalize the first letter of the firstString
        let variableNameCapitalized = variableName
        if (location !== 'Americas') {
            variableNameCapitalized =
                variableName.charAt(0).toUpperCase() + variableName.slice(1)
        }

        // Concatenate the secondString and capitalizedFirstString
        const result = locationalias[location] + variableNameCapitalized

        return result
    },
}
