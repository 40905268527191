import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Image from 'components/generic/Image'
import Button from 'components/generic/Button'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

const useStyles = makeStyles(theme => ({
    wrapper: {
        background: 'white',
        borderRadius: '12px',
        overflow: 'hidden',
        flex: 1,
    },
    top: {
        height: '200px',
        maxWidth: 'min(100%, 400px)',
        aspectRatio: '16/9',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer',
    },
    topWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    topLeft: {
        justifyContent: 'flex-start',
    },

    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '15px',
    },
    organiser: {
        position: 'absolute',
        top: '5%',
        left: '2%',
    },
    bottom: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    bolded: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'center',
        gap: '10px',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
}))

const EventCard = ({ event, buttons }) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const organization = event?.organizations
    // {event.published && !event.approved ? 'Waiting approval' : null}
    return (
        <div className={classes.wrapper}>
            <a
                className={classes.top}
                onClick={() => dispatch(push('/events/' + event.slug))}
            >
                <Image
                    className={classes.image}
                    defaultImage={require('assets/images/default_cover_image.png')}
                    publicId={event?.coverImage?.publicId}
                    transformation={{
                        width: 400,
                    }}
                />
                {organization?.icon && (
                    <Avatar
                        className={classes.organiser}
                        src={organization?.icon}
                    />
                )}
            </a>
            <div className={classes.bottom}>
                <Box width="100%" height="4em" margin="0">
                    <Typography variant="h6">{event.name}</Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="wrap"
                    justifyContent="center"
                    mt={2}
                    className={classes.uppercase}
                >
                    <Typography variant="body1" className={classes.bolded}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            style={{ marginTop: '2px' }}
                        >
                            <g id="Pathed">
                                <path
                                    id="Line (Stroke)"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M5 0.5C5.27614 0.5 5.5 0.723858 5.5 1V2.5H12.5V1C12.5 0.723858 12.7239 0.5 13 0.5C13.2761 0.5 13.5 0.723858 13.5 1V2.5H17C17.2761 2.5 17.5 2.72386 17.5 3V17C17.5 17.2761 17.2761 17.5 17 17.5H1C0.723858 17.5 0.5 17.2761 0.5 17V3C0.5 2.72386 0.723858 2.5 1 2.5H4.5V1C4.5 0.723858 4.72386 0.5 5 0.5ZM4.5 3.5H1.5V16.5H16.5V3.5H13.5V5C13.5 5.27614 13.2761 5.5 13 5.5C12.7239 5.5 12.5 5.27614 12.5 5V3.5H5.5V5C5.5 5.27614 5.27614 5.5 5 5.5C4.72386 5.5 4.5 5.27614 4.5 5V3.5Z"
                                    fill="#383946"
                                />
                                <path
                                    id="Line (Stroke)_2"
                                    opacity="0.3"
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M3.5 7.5C3.5 7.22386 3.72386 7 4 7H14C14.2761 7 14.5 7.22386 14.5 7.5C14.5 7.77614 14.2761 8 14 8H4C3.72386 8 3.5 7.77614 3.5 7.5Z"
                                    fill="#383946"
                                />
                            </g>
                        </svg>
                        {event?._eventTimeFormatted}
                    </Typography>
                    <Typography variant="body1">
                        {event?._eventLocationFormatted}
                    </Typography>
                    {/* <Button
                        variant="contained" color="primary" 
                            onClick={() => dispatch(push('/events/' + event.slug))}
                        >
                            See more
                        </Button> */}
                    {buttons?.map((btn, index) => (
                        <Box key={index}>{btn}</Box>
                    ))}
                </Box>
            </div>
        </div>
    )
}

export default EventCard
