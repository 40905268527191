import React, { useCallback } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { EventStatuses } from '@hackjunction/shared'

import * as AuthSelectors from 'redux/auth/selectors'
import RegistrationsService from 'services/registrations'
import * as UserSelectors from 'redux/user/selectors'
// import * as UserActions from 'redux/user/actions'
// import * as SnackbarActions from 'redux/snackbar/actions'

// TODO move to queries
const eventQuery = gql`
    query Event($slug: String!) {
        eventBySlug(slug: $slug) {
            name
            slug
            description
            metaDescription
            coverImage {
                url
                publicId
            }
            galleryOpen
            timezone
            galleryOpen
            emcaTimezone
            asiaTimezone
            startTime
            emcaStartTime
            asiaStartTime
            endTime
            emcaEndTime
            asiaEndTime
            asiaReviewingEndTime
            emcaReviewingEndTime
            reviewingEndTime
            registrationStartTime
            emcaRegistrationStartTime
            asiaRegistrationStartTime
            registrationEndTime
            emcaRegistrationEndTime
            asiaRegistrationEndTime
            registrationConfig {
                optionalFields
                requiredFields
            }
            challenges {
                name
                partner
                slug
                title
                subtitle
                description
                insights
                resources
                prizes
                criteria
                companyInfo
                logo {
                    url
                    publicId
                }
            }
            customQuestions {
                label
                name
                description
                questions {
                    settings {
                        options
                        default
                    }
                    hint
                    placeholder
                    fieldRequired
                    label
                    fieldType
                    name
                }
            }
            demoLabel
            demoHint
            finalists
            demoPlaceholder
            eventPrivacy
            frontPagePriority
            approved
            eventTerms
            eventTimeline {
                items {
                    title
                    startTime
                }
            }
            theme {
                headerBackgroundColor
                headerTextColor
                bodyBackgroundColor
                detailsBackgroundColor
                detailsTextColor
                sidebarBackgroundColor
                sidebarTextColor
                accentColor
                linkColor
            }
            tracksEnabled
            tracks {
                _id
                name
                slug
            }
            _eventStatus
            _eventTimeFormatted
            _eventLocationFormatted
        }
    }
`
// TODO specify all fields somewhere else
const registrationQuery = gql`
    query Registration($eventSlug: String!) {
        myRegistration(eventSlug: $eventSlug) {
            status
            answers {
                spokenLanguages
                role
                skills {
                    level
                    skill
                }
                CustomAnswers {
                    section
                    key
                    value
                }
                dietaryRestrictions
                industriesOfInterest
                themesOfInterest
                #email
                # lastName
                # firstName
                secretCode
                recruitmentOptions {
                    status
                    consent
                    relocation
                }
                teamOptions {
                    applyAsTeam
                    applyAlone
                }
                needsAccommodation
                needsTravelGrant
                needsVisa
                countryOfTravel
                linkedin
                github
                curriculumVitae
                portfolio
                education {
                    level
                    university
                    degree
                    graduationYear
                }
                motivation
                biography
                headline
                hackModule
                # expertise {

                # }
                mailingAddress
                category
                division
                office
                ideaAboutProject
                collaborationInOffice
                cityOfResidence
                countryOfResidence
                nationality
                tShirtSize
                gender
                dateOfBirth
                phoneNumber {
                    number
                    countryCode
                }
            }
        }
    }
`
const EventDetailContext = React.createContext({})
export const EventDetailProvider = ({ children }) => {
    const match = useRouteMatch()
    const idToken = useSelector(AuthSelectors.getIdToken)
    const user = useSelector(AuthSelectors.idTokenData)
    // const userProfile = useSelector(UserSelectors.userProfile)
    // const dispatch = useDispatch()
    const { slug } = match.params
    const {
        data: eventData,
        loading: eventLoading,
        error: eventError,
        refetch: refetchEvent,
    } = useQuery(eventQuery, {
        variables: {
            slug: slug,
        },
    })

    let advisor = false

    if (user) {
        if (user.roles) {
            // if (userProfile.role === 'Advisor') {
            if (user.roles.includes('Advisor')) {
                advisor = true
            } else {
                advisor = false
            }
        }
    }

    const {
        data: registrationData,
        loading: registrationLoading,
        error: registrationError,
        refetch: refetchRegistration,
    } = useQuery(registrationQuery, {
        variables: {
            eventSlug: slug,
        },
    })
    const createRegistration = useCallback(
        formData => {
            console.log('create Registration', idToken, slug, formData)
            formData = { ...formData, advisor: advisor }
            return RegistrationsService.createRegistration(
                idToken,
                slug,
                formData,
            ).then(res => {
                refetchRegistration()
                return res
            })
        },
        [advisor, idToken, refetchRegistration, slug],
    )

    const editRegistration = useCallback(
        formData => {
            console.log('edit Registration', idToken, slug, formData)
            formData = { ...formData, advisor: advisor }
            return RegistrationsService.updateRegistration(
                idToken,
                slug,
                formData,
            ).then(res => {
                refetchRegistration()
                return res
            })
        },
        [advisor, idToken, refetchRegistration, slug],
    )

    const finishRegistration = useCallback(
        formData => {
            console.log('finish Registration', idToken, slug, formData)
            formData = { ...formData, advisor: advisor }
            return RegistrationsService.finishRegistration(
                idToken,
                slug,
                formData,
            ).then(res => {
                refetchRegistration()
                return res
            })
        },
        [advisor, idToken, refetchRegistration, slug],
    )

    const event = eventData?.eventBySlug
    const registration = registrationData?.myRegistration
    const isRegistrationOpen =
        event?._eventStatus === EventStatuses.REGISTRATION_OPEN.id
    // console.log('registration Data____', registrationData)
    return (
        <EventDetailContext.Provider
            value={{
                slug,
                event,
                eventLoading,
                eventError,
                refetchEvent,
                registration,
                registrationLoading,
                registrationError,
                refetchRegistration,
                createRegistration,
                editRegistration,
                finishRegistration,
                hasRegistration: !!registration,
                isRegistrationOpen,
            }}
        >
            {children}
        </EventDetailContext.Provider>
    )
}

export default EventDetailContext
