import React, { useEffect, useMemo, useState } from 'react'

import { Box, Dialog, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash-es'

import PageWrapper from 'components/layouts/PageWrapper'

import LoadingSpinner from 'components/loadingCircle'
import ProjectDetail from 'components/projects/ProjectDetail'
import * as AuthSelectors from 'redux/auth/selectors'
import * as DashboardActions from 'redux/dashboard/actions'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import * as OrganiserSelectors from 'redux/organiser/selectors'
import * as OrganiserActions from 'redux/organiser/actions'
import ProjectsService from 'services/projects'
import WinnerDisplay from 'components/winnerDisplay'
// import Scoring from './Scoring'

function Winner({ event }) {
    const dispatch = useDispatch()
    const user = useSelector(AuthSelectors.idTokenData)
    const team = useSelector(DashboardSelectors.team)
    // const annotator = useSelector(DashboardSelectors.annotator)
    // const annotatorError = useSelector(DashboardSelectors.annotatorError)
    // const annotatorLoading = useSelector(DashboardSelectors.annotatorLoading)
    const rankedProjects = useSelector(OrganiserSelectors.rankedProjects)
    const projectsByRating = useSelector(DashboardSelectors.projectsByRating)
    const allProjectsMap = useSelector(OrganiserSelectors.projectsMap)
    const [selected, setSelected] = useState(false)
    const [scores, setScores] = useState([])
    console.log(rankedProjects)
    const callUnrankedProjects = () => {
        let unrankedProjects = []
        if (projectsByRating) {
            if (rankedProjects) {
                // if (rankedProjects == 'none') {
                // } else {
                unrankedProjects = projectsByRating.filter(project => {
                    if (project.isWinner) {
                        let found = false
                        rankedProjects.forEach(rankedProject => {
                            if (rankedProject._id === project._id) {
                                found = true
                            }
                        })
                        if (!found) {
                            return true
                        }
                    }
                    return false
                })
                // }
            }
        }

        return unrankedProjects
    }
    const unrankedProjects = callUnrankedProjects()
    const eventDescription = () => {
        if (projectsByRating.length === 0) {
            return (
                <p>
                    The list of winners of this hackathon will be displayed in
                    this section once the winners have been selected.
                </p>
            )
        }
    }
    const handleScoring = (projectId, score, feedback) => {
        console.log(score)
        dispatch(DashboardActions.giveScore(projectId, score, event.slug))
        dispatch(DashboardActions.giveFeedback(projectId, feedback, event.slug))
        dispatch(DashboardActions.getProjectsByRating(event.slug))
    }
    
    const { byChallenge, byTrack } = useMemo(() => {
        if (!rankedProjects) {
            return { byChallenge: {}, byTrack: {} };
            }
            console.log(rankedProjects)
    
        return rankedProjects.reduce(
            (res, project) => {
                console.log(project);
                if (project.track) {
                    if (res.byTrack.hasOwnProperty(project.track)) {
                        res.byTrack[project.track].push(project);
                    } else {
                        res.byTrack[project.track] = [project];
                    }
                }
    
                if (project.challenges) {
                    project.challenges.forEach(challenge => {
                        if (res.byChallenge.hasOwnProperty(challenge)) {
                            res.byChallenge[challenge].push(project);
                        } else {
                            res.byChallenge[challenge] = [project];
                        }
                    });
                }
    
                return res;
            },
            {
                byChallenge: {},
                byTrack: {},
            }
        );
    }, [rankedProjects]);
    
    let staredProjects = []

    useEffect(() => {
        if (projectsByRating && user) {
            projectsByRating.map(project => {
                // @ts-ignore
                if (user.sub) {
                    // @ts-ignore
                    if (project.usersThatRated.includes(user.sub)) {
                        staredProjects.push(project)
                    }
                }
            })
        }
    }, [projectsByRating, staredProjects, user])
    console.log(rankedProjects)

    useEffect(() => {
        console.log('from here', event.slug)
        dispatch(DashboardActions.getProjectsByRating(event.slug))
        dispatch(OrganiserActions.getRankedProjects(event.slug))
        dispatch(DashboardActions.updateAnnotator(event.slug))
    }, [event.slug, dispatch])

    if (!rankedProjects || !projectsByRating) {
        return <LoadingSpinner />
    }

    return (
        <PageWrapper>
            <Box textAlign="center" paddingTop="50px">
                <h1 className="text-3xl">🏆 Winners</h1>
            </Box>
            {eventDescription()}
            {event.tracks.map(track => {
                const items = byTrack[track.slug]
                if (!items) return null
                console.log(items);
                const sorted = sortBy(items, item => {
                    return -1 * item.description.length
                    })
                console.log(sorted.slice(0, 3));
                return (
                    <>  
                        <Box textAlign="center" paddingTop="40px" paddingBottom="20px">
                            <h1 className="text-3xl font-bold">{track.name}</h1>
                        </Box>
                        <WinnerDisplay
                            ranked={true}
                            event={event}
                            projectsByRating={items}
                            scores={scores}
                            setSelected={setSelected}
                            user={user}
                        />
                    </>
                )
            })}
            {/* <h1 className="text-xl mt-6">Unranked Projects</h1>
            <RenderContent
                ranked={false}
                event={event}
                projectsByRating={unrankedProjects}
                scores={scores}
                setSelected={setSelected}
                user={user}
            /> */}
            <Dialog
                transitionDuration={0}
                fullScreen
                open={Boolean(selected)}
                onClose={() => setSelected(false)}
            >
                <ProjectDetail
                    showFullTeam={true}
                    project={selected}
                    event={event}
                    onBack={() => setSelected(false)}
                    showTableLocation={false}
                    handleScoring={handleScoring}
                    scores={scores}
                />
            </Dialog>
        </PageWrapper>
    )
}

export default Winner

