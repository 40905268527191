import React, { useState } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import EventButtons from '../EventButtons'
import { useSelector } from 'react-redux'
import * as AuthSelectors from 'redux/auth/selectors'
import * as DashboardSelectors from 'redux/dashboard/selectors'
import * as OrganiserSelectors from 'redux/organiser/selectors'
import ExternalLink from 'components/generic/ExternalLink'
import * as UserSelectors from 'redux/user/selectors'
import { concatAndCapitalize } from '@hackjunction/shared/helpers/utils'

const useStyles = makeStyles(theme => ({
    root: {
        color: '#19DDEA',
        padding: '25px 0 0 0',
        textTransform: 'uppercase',
        fontSize: '18px',
    },
    location: {
        fontSize: '18px',
        textTransform: 'uppercase',
        color: '#6B6D85',
        paddingBottom: '20px',
    },
    date: {
        fontWeight: 'bold',
        paddingBottom: '12px',
        fontSize: '20px',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    marginUpper: {
        marginTop: theme.spacing(2),
    },
}))

const EventInformation = ({ event, registration }) => {
    const rankedProjects = useSelector(OrganiserSelectors.rankedProjects)
    const [selected, setSelected] = useState(false)
    const [scores, setScores] = useState([])
    const eventname = useSelector(DashboardSelectors.event)
    const userProfile = useSelector(UserSelectors.userProfile)

    let registrationStartTimeAlias = 'registrationStartTime'
    let registrationEndTimeAlias = 'registrationEndTime'
    let startTimeAlias = 'startTime'
    let endTimeAlias = 'endTime'

    if (userProfile.location) {
        registrationEndTimeAlias = concatAndCapitalize(
            registrationEndTimeAlias,
            userProfile.location,
        )
        registrationStartTimeAlias = concatAndCapitalize(
            registrationStartTimeAlias,
            userProfile.location,
        )
        startTimeAlias = concatAndCapitalize(
            startTimeAlias,
            userProfile.location,
        )
        endTimeAlias = concatAndCapitalize(endTimeAlias, userProfile.location)
    }
    const classes = useStyles()
    return (
        <>
            <Grid item xs={9} md={9} className={classes.marginUpper}>
                <Typography variant="body1" className={classes.date}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                    >
                        <g id="Pathed">
                            <path
                                id="Line (Stroke)"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5 0.5C5.27614 0.5 5.5 0.723858 5.5 1V2.5H12.5V1C12.5 0.723858 12.7239 0.5 13 0.5C13.2761 0.5 13.5 0.723858 13.5 1V2.5H17C17.2761 2.5 17.5 2.72386 17.5 3V17C17.5 17.2761 17.2761 17.5 17 17.5H1C0.723858 17.5 0.5 17.2761 0.5 17V3C0.5 2.72386 0.723858 2.5 1 2.5H4.5V1C4.5 0.723858 4.72386 0.5 5 0.5ZM4.5 3.5H1.5V16.5H16.5V3.5H13.5V5C13.5 5.27614 13.2761 5.5 13 5.5C12.7239 5.5 12.5 5.27614 12.5 5V3.5H5.5V5C5.5 5.27614 5.27614 5.5 5 5.5C4.72386 5.5 4.5 5.27614 4.5 5V3.5Z"
                                fill="#383946"
                            />
                            <path
                                id="Line (Stroke)_2"
                                opacity="0.3"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.5 7.5C3.5 7.22386 3.72386 7 4 7H14C14.2761 7 14.5 7.22386 14.5 7.5C14.5 7.77614 14.2761 8 14 8H4C3.72386 8 3.5 7.77614 3.5 7.5Z"
                                fill="#383946"
                            />
                        </g>
                    </svg>
                    {moment(event[startTimeAlias]).format('MMM D, YYYY')}
                </Typography>
                <Typography variant="body1" className={classes.location}>
                    {event._eventLocationFormatted}
                </Typography>
                <Box pt={2}>
                    <EventButtons event={event} registration={registration} />
                </Box>
            </Grid>
            {/* <Grid item xs={3} md={3}>
                <Typography variant="body1" className={classes.date}>
                    {event.name}
                </Typography>
            </Grid> */}
        </>
    )
}

export default EventInformation
