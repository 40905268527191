import React, { useContext } from 'react'
import EventDetailContext from '../../_events/slug/context'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { Grid, Typography } from '@material-ui/core'
import Container from 'components/generic/Container'
import EventCard from 'components/events/EventCard'
import Button from 'components/generic/Button'
import PageWrapper from 'components/layouts/PageWrapper'
import { useTranslation } from 'react-i18next'
import PastEvents from '../PastEvents'
import { concatAndCapitalize } from '@hackjunction/shared/helpers/utils'
import * as UserSelectors from 'redux/user/selectors'

export default ({ events, loading = false, title, past }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const userProfile = useSelector(UserSelectors.userProfile)

    let registrationStartTimeAlias = 'registrationStartTime'
    let registrationEndTimeAlias = 'registrationEndTime'
    let startTimeAlias = 'startTime'
    let endTimeAlias = 'endTime'

    if (userProfile && userProfile.location) {
        registrationEndTimeAlias = concatAndCapitalize(
            registrationEndTimeAlias,
            userProfile.location,
        )
        registrationStartTimeAlias = concatAndCapitalize(
            registrationStartTimeAlias,
            userProfile.location,
        )
        startTimeAlias = concatAndCapitalize(
            startTimeAlias,
            userProfile.location,
        )
        endTimeAlias = concatAndCapitalize(endTimeAlias, userProfile.location)
    }
    var date = new Date()
    const event = useContext(EventDetailContext)
    const isodate = date.toISOString()
    function renderEvents() {
        return events.map(event => {
            console.log(event)
            const canApply =
                isodate < event[registrationEndTimeAlias] &&
                isodate > event[registrationStartTimeAlias]

            const eventStarted = isodate > event[startTimeAlias]
            return (
                <Grid key={event.slug} item xs={12} md={6} lg={4}>
                    <EventCard
                        event={event}
                        buttons={[
                            !canApply && !eventStarted && (
                                <Button
                                    color="theme_lightgray"
                                    variant="outlinedNew"
                                    strong
                                    onClick={() =>
                                        dispatch(push('/events/' + event.slug))
                                    }
                                >
                                    {t('See_more_')}
                                </Button>
                            ),
                            // canApply && !event.galleryOpen && (
                            //     <Button
                            //         color="theme_lightgray"
                            //         variant="outlinedNew"
                            //         strong
                            //         onClick={() =>
                            //             dispatch(
                            //                 push(
                            //                     '/events/' +
                            //                         event.slug +
                            //                         '/register/',
                            //                 ),
                            //             )
                            //         }
                            //     >
                            //         {t('Register_now_')}
                            //     </Button>
                            // ),
                            event.galleryOpen && eventStarted && (
                                <Button
                                    color="primary"
                                    variant="outlinedNew"
                                    strong
                                    onClick={() =>
                                        dispatch(
                                            push('/projects/' + event.slug),
                                        )
                                    }
                                >
                                    {t('View_projects_')}
                                </Button>
                            ),
                        ]}
                    />
                </Grid>
            )
        })
    }

    if (!events || events.length === 0) {
        return null
    }
    console.log(events)
    return (
        <PageWrapper
            loading={loading}
            render={() => (
                <Container center>
                    <Grid
                        container
                        spacing={6}
                        direction="row"
                        alignItems="stretch"
                        justify="center"
                    >
                        <Grid item xs={12}>
                            <Typography variant="h3" align="center">
                                {title}
                            </Typography>
                        </Grid>
                        {renderEvents()}
                        {past ? <PastEvents /> : ''}
                    </Grid>
                </Container>
            )}
        />
    )
}
