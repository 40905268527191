const OverallReviewingMethods = {
    // finalsPublicVoting: {
    //     id: 'finalsPublicVoting',
    //     label: 'Finals, public voting',
    // },
    // finalsManualSelection: {
    //     id: 'finalsManualSelection',
    //     label: 'Finals, manual selection',
    // },
    noOverallWinner: {
        id: 'noOverallWinner',
        label: 'No overall winner',
    },
}

module.exports = OverallReviewingMethods
