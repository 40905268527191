import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import ExternalLink from 'components/generic/ExternalLink'
import Divider from 'components/generic/Divider'
import { useTranslation } from 'react-i18next'
import config from 'constants/config'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    wrapper: {
        background: theme.palette.theme_black.main,
        padding: theme.spacing(2),
    },
    inner: {
        width: '100%',
        maxWidth: '1120px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
        },
    },
    innerSecond: {
        width: '100%',
        maxWidth: '1120px',
        margin: '48px auto 24px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    copyright: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
        color: 'white',
        width: '20%',
    },
    links: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        gap: '48px',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-end',
            textAlign: 'right',
        },
    },
    logos: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        },
    },
    white: {
        color: 'white',
    },
    align: {
        '& button': {
            marginLeft: 0,
        },
    },
    innest: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        '& button': {
            alignSelf: 'flex-end',
            marginRight: 0,
        },
    },
}))

const EventFooter = props => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    return (
        <div className={classes.wrapper}>
            {/* <Grid container className={classes.innerSecond}> */}
            {/* <Grid item xs={12} md={6} xl={6} className={classes.align}>
                    <Typography variant="h4" className={classes.white}>
                        {t('Platform_organise_hack_', {
                            owner: config.PLATFORM_OWNER_NAME,
                        })}
                    </Typography>
                    <Button
                        color="theme_lightgrayDark"
                        variant="outlinedNew"
                        strong
                        onClick={() => dispatch(push('/contact'))}
                    >
                        {t('Contact_us_')}
                    </Button>
                    <Button
                        color="theme_lightgrayDark"
                        variant="outlinedNew"
                        strong
                        onClick={() => dispatch(push('/pricing'))}
                    >
                        Pricing
                    </Button>
                </Grid> */}
            {/* <Hidden xsDown>
                    <Grid item xs={6} md={6} x={6} className={classes.innest}>
                        <Typography variant="h4" className={classes.white}>
                            {t('Join_hackerpack_')}
                        </Typography>
                        <Button
                            color="theme_lightgrayDark"
                            variant="outlinedNew"
                            strong
                            onClick={() => dispatch(push('/hackerpack'))}
                            className={classes.align}
                        >
                            {t('To_hackerpack_')}
                        </Button>
                    </Grid>
                </Hidden> */}
            {/* </Grid> */}
            <Divider size={5} />

            <div className={classes.inner}>
                <Box width="70%">
                    <Box
                        display="flex"
                        gridGap="27px"
                        flexDirection="column"
                        marginBottom="48px"
                    >
                        <img
                            src={require('assets/logos/Harness.png')}
                            width="150"
                            alt="Harness"
                        ></img>
                        {/* <Box display="flex" gridGap="26px">
                            <img
                                src={require('assets/logos/github.png')}
                                width="24"
                                alt="github"
                            ></img>
                            <img
                                src={require('assets/logos/linkedin.png')}
                                width="24"
                                alt="linkedin"
                            ></img>
                            <img
                                src={require('assets/logos/facebook.png')}
                                width="24"
                                alt="facebook"
                            ></img>
                            <img
                                src={require('assets/logos/instagram.png')}
                                width="24"
                                alt="instagram"
                            ></img>
                            <img
                                src={require('assets/logos/twitter.png')}
                                width="24"
                                alt="twitter"
                            ></img>
                        </Box> */}
                    </Box>
                    <div className={classes.links}>
                        <ExternalLink theme="footer" href={config.TERMS_URL}>
                            {t('Terms_')}
                        </ExternalLink>
                        <ExternalLink theme="footer" href={config.PRIVACY_URL}>
                            {t('Privacy_')}
                        </ExternalLink>
                        <ExternalLink
                            theme="footer"
                            href={config.PLATFORM_OWNER_WEBSITE}
                        >
                            {t('Website_', {
                                owner: config.PLATFORM_OWNER_NAME,
                            })}
                        </ExternalLink>
                    </div>
                </Box>
                <div className={classes.copyright}>
                    <Divider size={1} />
                    <span className={classes.copyright}>
                        Designed and developed by Harness team, with the help
                        of:
                    </span>
                    <Divider size={1} />
                    <div className={classes.logos}>
                        <a
                            width="150"
                            height="50"
                            href="https://auth0.com/?utm_source=oss&utm_medium=gp&utm_campaign=oss"
                            target="_blank"
                            rel="noopener noreferrer"
                            alt="Single Sign On & Token Based Authentication - Auth0"
                        >
                            <img
                                width="150"
                                height="50"
                                alt="JWT Auth for open source projects"
                                src="//cdn.auth0.com/oss/badges/a0-badge-light.png"
                            />
                        </a>
                    </div>
                    <Divider size={1} />
                </div>
            </div>
        </div>
    )
}
EventFooter.defaultProps = {
    hide_contact: false,
}
export default EventFooter
