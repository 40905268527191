import * as ActionTypes from './actionTypes'
import { buildHandler, buildUpdatePath } from '../utils'
import { handle } from 'redux-pack'
import { projectsByRating } from './selectors'
import { getIsSubmisstionsUpcoming } from './actions'

const initialState = {
    event: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    registration: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    team: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    teams: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    projects: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    comments: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    projectsByRating: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    scores: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    finalistTop10: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    annotator: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    rating: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    score: {
        data: null,
        loading: true,
        error: false,
        updated: 0,
    },
    project_scores: {
        data: [],
        loading: true,
        error: false,
        updated: 0,
    },
    isSubmissionsUpcoming: {
        data: [],
        loading: true,
        error: false,
        updated: 0,
    },
    allTeams: {
        data: [],
        loading: true,
        error: false,
        updated: 0,
        map: {},
    },
    registrations: {
        data: [],
        loading: true,
        error: false,
        updated: 0,
        map: {},
    },
}

const updateEventHandler = buildHandler('event')
const updateProjectsByRatingHandler = buildHandler('projectsByRating')
const updateScores = buildHandler('scores')
const updateFinalistTop10 = buildHandler('finalistTop10')
const updateRegistrationHandler = buildHandler('registration')
const updateTeamHandler = buildHandler('team')
const updateProjectsHandler = buildHandler('projects', '_id')
const updateAnnotatorHandler = buildHandler('annotator')
const updateProjectScoresHandler = buildHandler('project_scores')
const updateCommentsHandler = buildHandler('comments')
const getTeams = buildHandler('teams')
const editRegistration = buildUpdatePath('registration.data')
const editTeam = buildUpdatePath('team.data')
const editAnnotator = buildUpdatePath('annotator.data')
const isSubmissionsUpcoming = buildHandler('isSubmissionsUpcoming')
const allTeamsHandler = buildHandler('teams')
const registrationsHandler = buildHandler('registrations', 'user')

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_EVENT: {
            return updateEventHandler(state, action)
        }
        case ActionTypes.GET_TEAMS: {
            return getTeams(state, action)
        }
        case ActionTypes.UPDATE_REGISTRATION: {
            return updateRegistrationHandler(state, action)
        }
        case ActionTypes.GET_COMMENTS: {
            return updateCommentsHandler(state, action)
        }

        case ActionTypes.UPDATE_TEAM: {
            return updateTeamHandler(state, action)
        }
        case ActionTypes.UPDATE_PROJECTS: {
            return updateProjectsHandler(state, action)
        }
        case ActionTypes.UPDATE_ANNOTATOR: {
            return updateAnnotatorHandler(state, action)
        }
        case ActionTypes.UPDATE_PROJECT_SCORES: {
            return updateProjectScoresHandler(state, action)
        }
        case ActionTypes.EDIT_REGISTRATION: {
            return editRegistration(state, action.payload)
        }
        case ActionTypes.EDIT_TEAM: {
            return editTeam(state, action.payload)
        }
        case ActionTypes.EDIT_ANNOTATOR: {
            return editAnnotator(state, action.payload)
        }
        case ActionTypes.CLEAR_TEAM: {
            return editTeam(state, initialState.team.data)
        }
        case ActionTypes.GET_PROJECTS_BY_RATING: {
            return updateProjectsByRatingHandler(state, action)
        }
        case ActionTypes.GET_SCORES: {
            return updateScores(state, action)
        }
        case ActionTypes.GET_FINALIST_TOP10: {
            return updateFinalistTop10(state, action)
        }
        case ActionTypes.GIVE_SCORE: {
            return state
        }
        case ActionTypes.GET_IS_SUBMISSIONS_UPCOMING: {
            return isSubmissionsUpcoming(state, action)
        }
        case ActionTypes.UPDATE_REGISTRATIONS: {
            return registrationsHandler(state, action)
        }
        // case ActionTypes.UPDATE_TEAMS: {
        //     const newState = allTeamsHandler(state, action)
        //     if (action.payload) {
        //         const byUser = action.payload.reduce((map, team) => {
        //             map[team.owner] = team
        //             team.members.forEach(member => {
        //                 map[member] = team
        //             })
        //             return map
        //         }, {})
        //         return {
        //             ...newState,
        //             allTeams: {
        //                 ...newState.allTeams,
        //                 map: byUser,
        //             },
        //         }
        //     } else {
        //         return newState
        //     }
        // }
        // case ActionTypes.RATE_PROJECT: {
        //     return {
        //         ...state,
        //         projectsByRating: {
        //             ...state.projectsByRating,
        //             data: projectsByRating(state).map(project => {
        //                 if (project._id === action.payload.projectId) {
        //                     return {
        //                         ...project,
        //                         rating: project.rating + 1,
        //                         usersThatRated: [
        //                             ...project.usersThatRated,
        //                             action.payload.userId,
        //                         ],
        //                     }
        //                 }
        //                 return project
        //             }),
        //         },
        //     }
        // }
        // case ActionTypes.UNRATE_PROJECT: {
        //     return {
        //         ...state,
        //         projectsByRating: {
        //             ...state.projectsByRating,
        //             data: projectsByRating(state).map(project => {
        //                 if (project._id === action.payload.projectId) {
        //                     return {
        //                         ...project,
        //                         rating: project.rating - 1,
        //                         usersThatRated: project.usersThatRated.filter(
        //                             id => id !== action.payload.userId,
        //                         ),
        //                     }
        //                 }
        //                 return project
        //             }),
        //         },
        //     }
        // }
        default:
            return state
    }
}
