import React, { useState, useEffect, useCallback, useMemo } from 'react'
import ShowIfPermission from 'hocs/ShowIfPermission'
import { Auth as AuthConstants } from '@hackjunction/shared'
import ExternalLink from 'components/generic/ExternalLink'
import { useSelector } from 'react-redux'
import * as AuthSelectors from 'redux/auth/selectors'
import { makeStyles } from '@material-ui/core/styles'

import {
    CircularProgress,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Grid,
} from '@material-ui/core'

import UserProfilesService from 'services/userProfiles'

const useStyles = makeStyles(theme => ({
    avatar: {
        width: '16px',
        height: '16px',
    },
    avatar_wrapper: {
        minWidth: 'auto',
    },
    list_wrapper: {
        padding: '0',
        gap: '2px',
    },
    member_name: {
        color: '#4F5162',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
        flex: 'none',
        '& span': {            
            whiteSpace: 'nowrap'
        }
    },
    list: {
        display: 'grid',
        // flexWrap: 'nowrap',
        gap: '6px 12px',
        gridTemplateColumns: '1fr 1fr',
    },
    advisor_tag: {
        background: '#3DC7F6',
        padding: '0px 4px',
        lineHeight: '20px',
        fontSize: '12px',
        color: 'white',
    },
}))
const RecruitmentLink = ({ memberId }) => {
    return (
        <ExternalLink
            href={`${process.env.REACT_APP_BASE_URL}/recruitment/${memberId}`}
        >
            <ListItemText>Interested in recruitment</ListItemText>
        </ExternalLink>
    )
}

const IfRecruiter = ShowIfPermission(RecruitmentLink, [
    AuthConstants.Permissions.ACCESS_RECRUITMENT,
])

const ProjectTeam = React.memo(({ hiddenUsers, teamId, showFullTeam }) => {
    const [teamMembers, setTeamMembers] = useState()
    const [loading, setLoading] = useState(false)
    const idToken = useSelector(AuthSelectors.getIdToken)
    const hasRecruiterAccess = useSelector(AuthSelectors.hasRecruiterAccess)
    const classes = useStyles()

    // TODO IMPORTANT hide team members in backend
    console.log(teamMembers)
    const fetchTeamMembers = useCallback(async () => {
        if (!teamId) return
        setLoading(true)
        try {
            if (hasRecruiterAccess) {
                console.log('has recruiter access')
                const data = await UserProfilesService.getUserProfilesByTeamId(
                    teamId,
                    idToken,
                )
                console.log('data', data)
                setTeamMembers(
                    data.filter(i => !hiddenUsers.includes(i.userId)),
                )
            } else {
                const data =
                    await UserProfilesService.getPublicUserProfilesByTeam(
                        teamId,
                    )
                console.log('data', data)
                setTeamMembers(
                    data.filter(i => !hiddenUsers.includes(i.userId)),
                )
            }
        } catch (err) {}
        setLoading(false)
    }, [hasRecruiterAccess, hiddenUsers, idToken, teamId])

    useEffect(() => {
        fetchTeamMembers()
    }, [fetchTeamMembers])

    if (loading) {
        return (
            <Box p={2}>
                <CircularProgress />
            </Box>
        )
    }

    if (!teamMembers) {
        return null
    }

    const secondaryText = member => {
        if (!showFullTeam) return null
        return `${member.email}
         ${member.phoneNumber ? member.phoneNumber.countryCode : ''} ${
            member.phoneNumber ? member.phoneNumber.number : ''
        }`
    }

    console.log(teamMembers)
    return (
        <List className={classes.list}>
            {teamMembers.map(member => {
                console.log(member)
                return (
                    <Grid item xs={12} md={6} lg={6}>
                        <ListItem
                            key={member.userId}
                            className={classes.list_wrapper}
                        >
                            <ListItemAvatar className={classes.avatar_wrapper}>
                                <Avatar
                                    src={member.avatar}
                                    className={classes.avatar}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                className={classes.member_name}
                                primary={`${member.firstName} ${member.lastName}`}
                                secondary={''}
                            />
                            {member.advisor && (
                                <div className={classes.advisor_tag}>Adv</div>
                            )}
                            {typeof member.recruitmentOptions !== 'undefined' &&
                                member.recruitmentOptions.consent && (
                                    <IfRecruiter memberId={member.userId} />
                                )}
                        </ListItem>
                    </Grid>
                )
            })}
        </List>
    )
})

export default ProjectTeam
