import { push } from 'connected-react-router'

import * as ActionTypes from './actionTypes'
import * as AuthSelectors from '../auth/selectors'
import * as DashboardSelectors from './selectors'
import EventsService from 'services/events'
import ProjectsService from 'services/projects'
import RegistrationsService from 'services/registrations'
import TeamsService from 'services/teams'
import ProjectScoresService from 'services/projectScores'

import GavelService from 'services/reviewing/gavel'
import UserProfilesService from 'services/userProfiles'
import moment from 'moment'
import {
    EventHelpers,
    // EventStatuses,
    // EventTypes,
    // RegistrationStatuses,
    // ReviewingMethods,
    // RegistrationTravelGrantStatuses as TravelGrantStatuses
} from '@hackjunction/shared'

export const updateEvent = slug => dispatch => {
    dispatch({
        type: ActionTypes.UPDATE_EVENT,
        promise: EventsService.getPublicEventBySlug(slug),
        meta: {
            onFailure: e => console.log('Error updating dashboard event', e),
        },
    })
}

export const updateRegistration = slug => (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    dispatch({
        type: ActionTypes.UPDATE_REGISTRATION,
        promise: RegistrationsService.getRegistration(idToken, slug),
        meta: {
            onFailure: () => dispatch(push('/')),
        },
    })
}

export const updateRegistrationGrantDetails =
    (slug, data) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())

        try {
            const registration =
                await RegistrationsService.updateTravelGrantDetails(
                    idToken,
                    slug,
                    data,
                )

            dispatch({
                type: ActionTypes.EDIT_REGISTRATION,
                payload: registration,
            })
            return
        } catch (err) {
            return err
        }
    }

export const confirmRegistration = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const registration = await RegistrationsService.confirmRegistration(
        idToken,
        slug,
    )

    dispatch({
        type: ActionTypes.EDIT_REGISTRATION,
        payload: registration,
    })

    return registration
}

export const cancelRegistration = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const registration = await RegistrationsService.cancelRegistration(
        idToken,
        slug,
    )

    dispatch({
        type: ActionTypes.EDIT_REGISTRATION,
        payload: registration,
    })

    return registration
}

export const editRegistration = (slug, data) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const registration = await RegistrationsService.updateRegistration(
        idToken,
        slug,
        data,
    )

    dispatch({
        type: ActionTypes.EDIT_REGISTRATION,
        payload: registration,
    })

    return registration
}

export const createRegistration =
    (slug, data) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())

        const registration = await RegistrationsService.createRegistration(
            idToken,
            slug,
            data,
        )

        dispatch({
            type: ActionTypes.EDIT_REGISTRATION,
            payload: registration,
        })

        return registration
    }

export const updateTeam = slug => (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    dispatch({
        type: ActionTypes.UPDATE_TEAM,
        promise: TeamsService.getTeamForEvent(idToken, slug, true).catch(
            err => {
                if (err.response.status === 404) {
                    return Promise.resolve(null)
                }
                return Promise.reject(err)
            },
        ),
        meta: {
            onFailure: e => console.log('Error updating dashboard team', e),
        },
    })
}

export const getTeams = slug => (dispatch, getState) => {
    console.log('getTeams action')
    const idToken = AuthSelectors.getIdToken(getState())
    // const teams = TeamsService.getTeams(idToken, slug)
    console.log('getTeams action')
    dispatch({
        type: ActionTypes.GET_TEAMS,
        promise: TeamsService.getTeams(idToken, slug, true),
        meta: {
            onFailure: e => console.log('Error getting teams', e),
        },
    })
}

export const createTeam = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())
    const team = await TeamsService.createTeamForEvent(idToken, slug, true)

    dispatch({
        type: ActionTypes.EDIT_TEAM,
        payload: team,
    })

    return team
}

export const joinTeam = (slug, code) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const team = await TeamsService.joinTeamForEvent(idToken, slug, code, true)

    dispatch({
        type: ActionTypes.EDIT_TEAM,
        payload: team,
    })

    return team
}

export const leaveTeam = (slug, code) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const team = await TeamsService.leaveTeamForEvent(idToken, slug, code)

    dispatch({
        type: ActionTypes.CLEAR_TEAM,
    })

    return team
}

export const removeMemberFromTeam =
    (slug, code, userId) => async (dispatch, getState) => {
        const state = getState()
        const idToken = AuthSelectors.getIdToken(state)
        const oldTeam = DashboardSelectors.team(state)
        const team = await TeamsService.removeMemberFromTeam(
            idToken,
            slug,
            code,
            userId,
        )

        dispatch({
            type: ActionTypes.EDIT_TEAM,
            payload: {
                ...team,
                meta: oldTeam.meta,
            },
        })

        return team
    }

export const deleteTeam = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())
    const team = await TeamsService.deleteTeamForEvent(idToken, slug)

    dispatch({
        type: ActionTypes.CLEAR_TEAM,
    })

    return team
}

export const lockTeam = (slug, code) => async (dispatch, getState) => {
    const state = getState()
    const idToken = AuthSelectors.getIdToken(state)
    const oldTeam = DashboardSelectors.team(state)
    const team = await TeamsService.lockTeamForEvent(idToken, slug, code)

    dispatch({
        type: ActionTypes.EDIT_TEAM,
        payload: {
            ...team,
            meta: oldTeam.meta,
        },
    })

    return team
}

export const updateProjects = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    return dispatch({
        type: ActionTypes.UPDATE_PROJECTS,
        promise: ProjectsService.getProjectsForEventAndTeam(idToken, slug),
        meta: {
            onFailure: e => console.log('Error updating dashboard project', e),
        },
    })
}

export const createProject = (slug, data) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    await ProjectsService.createProjectForEventAndTeam(idToken, slug, data)
    return dispatch({
        type: ActionTypes.UPDATE_PROJECTS,
        promise: ProjectsService.getProjectsForEventAndTeam(idToken, slug),
        meta: {
            onFailure: e => console.log('Error creating dashboard project', e),
        },
    })
}

export const editProject = (slug, data) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    await ProjectsService.updateProjectForEventAndTeam(idToken, slug, data)
    return dispatch({
        type: ActionTypes.UPDATE_PROJECTS,
        promise: ProjectsService.getProjectsForEventAndTeam(idToken, slug),
        meta: {
            onFailure: e => console.log('Error editing dashboard project', e),
        },
    })
}

export const updateAnnotator = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    const { error } = await dispatch({
        type: ActionTypes.UPDATE_ANNOTATOR,
        promise: GavelService.getAnnotator(idToken, slug), //,
        meta: {
            onFailure: e => console.log('Error updating annotator', e),
        },
    })

    return error
}

export const updateProjectScores = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    return dispatch({
        type: ActionTypes.UPDATE_PROJECT_SCORES,
        promise: ProjectScoresService.getScoresByEventAndTeam(idToken, slug),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const beginVoting = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    try {
        const annotator = await GavelService.beginVoting(idToken, slug)
        dispatch({
            type: ActionTypes.EDIT_ANNOTATOR,
            payload: annotator,
        })
        return
    } catch (err) {
        console.log(err)
        return err
    }
}

export const skipProject = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    try {
        const annotator = await GavelService.skipProject(idToken, slug)
        dispatch({
            type: ActionTypes.EDIT_ANNOTATOR,
            payload: annotator,
        })
        return
    } catch (err) {
        console.log(err)
        return err
    }
}

export const setFirstProjectSeen = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    try {
        const annotator = await GavelService.setFirstProjectSeen(idToken, slug)
        dispatch({
            type: ActionTypes.EDIT_ANNOTATOR,
            payload: annotator,
        })
        return
    } catch (err) {
        console.log(err)
        return err
    }
}

export const submitVote = (slug, winnerId) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    try {
        const annotator = await GavelService.submitVote(idToken, slug, winnerId)
        dispatch({
            type: ActionTypes.EDIT_ANNOTATOR,
            payload: annotator,
        })
    } catch (err) {
        console.log(err)
        return err
    }
}

export const getScores = projectId => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())
    dispatch({
        type: ActionTypes.GET_SCORES,
        promise: ProjectsService.getScores(idToken, projectId),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const giveAllScores =
    (projectId, data) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.giveAllScores(idToken, projectId, data)
    }

export const giveAllGlobalScores =
    (projectId, data) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.giveAllGlobalScores(idToken, projectId, data)
    }

export const getProjectsByRating = slug => async (dispatch, getState) => {
    return dispatch({
        type: ActionTypes.GET_PROJECTS_BY_RATING,
        promise: ProjectsService.getFinalistProjects(slug),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const getFinalistTop10 = slug => async (dispatch, getState) => {
    return dispatch({
        type: ActionTypes.GET_FINALIST_TOP10,
        promise: ProjectsService.getTop10FinalistProjects(slug),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const rateProject =
    (projectId, userId, slug) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.rateProject(idToken, projectId)
        return dispatch({
            type: ActionTypes.GET_PROJECTS_BY_RATING,
            promise: ProjectsService.getFinalistProjects(slug),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

export const unrateProject =
    (projectId, userId, slug) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.unrateProject(idToken, projectId)
        return dispatch({
            type: ActionTypes.GET_PROJECTS_BY_RATING,
            promise: ProjectsService.getFinalistProjects(slug),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

export const giveScore =
    (projectId, score, slug) => async (dispatch, getState) => {
        console.log('giveScore', projectId, score)
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.giveScore(idToken, projectId, score)
        return dispatch({
            type: ActionTypes.GET_PROJECTS_BY_RATING,
            promise: ProjectsService.getFinalistProjects(slug),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

export const giveGlobalScore =
    (projectId, score, slug) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.giveScore(idToken, projectId, score)
        return dispatch({
            type: ActionTypes.GET_PROJECTS_BY_RATING,
            promise: ProjectsService.getFinalistProjects(slug),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

export const removeScore = (projectId, slug) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    await ProjectsService.removeScore(idToken, projectId)
    return dispatch({
        type: ActionTypes.GET_PROJECTS_BY_RATING,
        promise: ProjectsService.getFinalistProjects(slug),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
    // setTimeout(() => {
    //     dispatch({
    //         type: ActionTypes.UPDATE_PROJECTS,
    //         promise: ProjectsService.getAllProjectsAsOrganiser(idToken, slug),
    //         meta: {
    //             onFailure: e => console.log('Error getting projects', e),
    //         },
    //     })
    // }, 1000)

    // return dispatch({
    //     type: ActionTypes.GET_FINALIST_TOP10,
    //     promise: ProjectsService.getTop10FinalistProjects(slug),
    //     meta: {
    //         onFailure: e =>
    //             console.log('Error updating dashboard project scores', e),
    //     },
    // })
    // return dispatch({
    //     type: OrganizeActionTypes.UPDATE_PROJECTS,
    //     promise: ProjectsService.getAllProjectsAsOrganiser(idToken, slug),
    //     meta: {
    //         onFailure: e => console.log('Error getting projects', e),
    //     },
    // })
}

export const postComment =
    (projectId, parentId, data) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        await ProjectsService.postComment(idToken, parentId, data)
        dispatch({
            type: ActionTypes.GET_COMMENTS,
            promise: ProjectsService.getComments(projectId),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

export const getComments = projectId => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())
    dispatch({
        type: ActionTypes.GET_COMMENTS,
        promise: ProjectsService.getComments(projectId),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const makeWinner = (projectId, slug) => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())

    await ProjectsService.makeWinner(idToken, projectId)
    return dispatch({
        type: ActionTypes.GET_PROJECTS_BY_RATING,
        promise: ProjectsService.getFinalistProjects(slug),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const giveFeedback =
    (projectId, feedback, slug) => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())
        // await ProjectsService.giveFeedback(idToken, projectId, feedback)
        return dispatch({
            type: ActionTypes.GIVE_FEEDBACK,
            promise: ProjectsService.giveFeedback(idToken, projectId, feedback),
            // promise: ProjectsService.getTop10FinalistProjects(slug),
            meta: {
                onFailure: e =>
                    console.log('Error updating dashboard project scores', e),
            },
        })
    }

// export const eventStatus = createSelector(event, async event => {
//     const authSession = await Auth0Service.checkSession()
//     if (!authSession) {
//         return EventHelpers.getEventStatus(event, moment)
//     }
//     const userProfile = await UserProfilesService.getUserProfile(
//         authSession.idToken,
//     )
//     if (!userProfile) {
//         return EventHelpers.getEventStatus(event, moment)
//     }

//     return EventHelpers.getEventStatus(event, moment, userProfile.location)
// })

// export const getEventStatus = slug => async (dispatch, getState) => {
//     const idToken = AuthSelectors.getIdToken(getState())
//     const event = EventS
//     const userProfile = await UserProfilesService.getUserProfile(idToken)

//     return dispatch({
//         type: ActionTypes.GET_EVENT_STATUS,
//         promise: EventHelpers.,
//         meta: {
//             onFailure: e =>
//                 console.log('Error updating dashboard project scores', e),
//         },
//     })
// }

// export const isSubmissionsUpcoming = createSelector(event, async event => {
//     const authSession = await Auth0Service.checkSession()
//     if (!authSession) {
//         return EventHelpers.isSubmissionsUpcoming(event, moment)
//     }
//     const userProfile = await UserProfilesService.getUserProfile(
//         authSession.idToken,
//     )
//     if (!userProfile) {
//         return EventHelpers.isSubmissionsUpcoming(event, moment)
//     }

//     const response = EventHelpers.isSubmissionsUpcoming(
//         event,
//         moment,
//         userProfile.location,
//     )
//     console.log(response)
//     return response
// })

export const getIsSubmissionsUpcoming = slug => async (dispatch, getState) => {
    console.log('getIsSubmissionsUpcoming action')
    const idToken = AuthSelectors.getIdToken(getState())
    const event = await EventsService.getPublicEventBySlug(slug)
    const userProfile = await UserProfilesService.getUserProfile(idToken)

    return dispatch({
        type: ActionTypes.GET_IS_SUBMISSIONS_UPCOMING,
        promise: EventHelpers.isSubmissionsUpcoming(
            event,
            moment,
            userProfile.location,
        ),
        meta: {
            onFailure: e =>
                console.log('Error updating dashboard project scores', e),
        },
    })
}

export const updateRegistrationsForEvent =
    slug => async (dispatch, getState) => {
        const idToken = AuthSelectors.getIdToken(getState())

        if (!slug) return

        dispatch({
            type: ActionTypes.UPDATE_REGISTRATIONS,
            promise: RegistrationsService.getRegistrationsForEvent(
                idToken,
                slug,
            ),
            meta: {
                onFailure: e => console.log('Error updating registrations', e),
            },
        })
    }

export const updateTeamsForEvent = slug => async (dispatch, getState) => {
    const idToken = AuthSelectors.getIdToken(getState())
    if (!slug) return

    dispatch({
        type: ActionTypes.UPDATE_TEAMS,
        promise: TeamsService.getTeamsForEvent(idToken, slug),
        meta: {
            onFailure: e => console.log('Error updating teams', e),
        },
    })
}
