import Typography from '@material-ui/core/Typography'
import Scoring from 'pages/_dashboard/slug/rating/Scoring'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import LoadingSpinner from './loadingCircle'
import { Box, Grid } from '@material-ui/core'
// import ProjectTeam from './projects/ProjectDetail/ProjectTeam'
import ProjectTeam from './ProjectTeam'
import moment from 'moment'
import EventHelpers from '@hackjunction/shared/helpers/events'

const useStyles = makeStyles(theme => ({
    category: {
        textTransform: 'capitalize',
        background: '#CDF4FE',
        borderRadius: '24px',
        padding: '8px 16px',
        lineHeight: 'normal',
        color: '#0278D5',
    },
    content: {
        textAlign: 'center',
    },
    card_bg: {
        background: '#EFFBFF',
        borderRadius: '8px',
        boxShadow:
            '0px 4px 8px 0px rgba(96, 97, 112, 0.16), 0px 0px 2px 0px rgba(40, 41, 61, 0.04)',
        maxWidth: '388px',
    },
    card: {
        padding: '24px 16px',
    },
    team: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
}))

const WinnerDisplay = ({
    projectsByRating,
    setSelected,
    user,
    scores,
    event,
    ranked,
}) => {
    const classes = useStyles()
    if (!projectsByRating) return <LoadingSpinner />
    // if (projectsByRating.length === 0)
    //     return (
    //         <div>
    //             The list of winners of this hackathon will be displayed in this
    //             section once the winners have been selected.
    //         </div>
    //     )
    return (
        <Grid container spacing={3} justifyContent="center">
            {projectsByRating.map((project, index) => {
                console.log(project)
                return (
                    <Grid
                        md={6}
                        xs={6}
                        onClick={() => {
                            setSelected(project)
                        }}
                        className="lg:flex hover:cursor-pointer justify-between text-gray-800 mb-4 p-4 rounded-lg "
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            textAlign="center"
                        >
                            {ranked && (
                                <div className="mb-2">
                                    {(index + 1) % 2 !== 0
                                        ? 
                                        <Typography variant="body1">
                                            <h3 className="text-gray-800 text-xl text-center font-semibold">
                                                Winner
                                            </h3>
                                        </Typography>
                                        : 
                                        <Typography variant="body1">
                                            <h3 className="text-gray-800 text-xl text-center">
                                                Runner Up
                                            </h3>
                                        </Typography>
                                    }
                                </div>
                            )}
                            <div
                                className={
                                    'mb-2 lg:mb-0 justify-center items-center ' +
                                    classes.card_bg
                                }
                            >
                                {/* {ranked && (
                                    <div className="mr-8 text-xl font-bold">
                                        {index + 1 + ')'}
                                    </div>
                                )} */}
                                <img
                                    src={
                                        (project.images[0] &&
                                            project.images[0].url) ||
                                        'https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg'
                                    }
                                    alt="project"
                                    className="w-full mr-0 lg:mr-2 object-cover rounded-md"
                                />
                                <div
                                    className={
                                        'flex flex-col justify-start lg:flex-none mb-2 lg:mb-0 ' +
                                        classes.card
                                    }
                                >
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        paddingBottom="10px"
                                    >
                                        <Typography
                                            variant="body1"
                                            className={classes.category}
                                        >
                                            {project.track}
                                        </Typography>
                                        <Box textAlign="start">
                                            {project.globalScore !== null &&
                                                EventHelpers.isEventOver(
                                                    event,
                                                    moment,
                                                    // userProfile.location,
                                                ) && (
                                                    <Typography
                                                        style={{
                                                            textTransform:
                                                                'uppercase',
                                                        }}
                                                        variant="body2"
                                                    >
                                                        Global Score:{' '}
                                                        {parseFloat(
                                                            project.globalScore,
                                                        ).toFixed(2)}
                                                    </Typography>
                                                )}
                                            {project.score !== null && (
                                                <Typography
                                                    style={{
                                                        textTransform: 'uppercase',
                                                    }}
                                                    variant="body2"
                                                >
                                                    Regional Score:{' '}
                                                    {parseFloat(
                                                        project.score,
                                                    ).toFixed(2)}
                                                </Typography>
                                            )}

                                        </Box>
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="h6">
                                            {project.name}
                                        </Typography>
                                        {/* <Typography variant="body1">
                                            {project.punchline}
                                        </Typography> */}
                                        <Typography variant="body1">
                                            <h3 className="text-gray-800 text-md text-center mt-2">
                                                {project.locationnew}
                                            </h3>
                                        </Typography>
                                    </Box>
                                    {/* <Typography variant="body1">
                                        <h3 className=" text-gray-800 text-md text-left">
                                            {project.description}
                                        </h3>
                                    </Typography> */}
                                    <ProjectTeam
                                        // @ts-ignore

                                        hiddenUsers={project.hiddenMembers}
                                        teamId={project.team}
                                        showFullTeam={true}
                                    />
                                    {/* <Typography variant="body1">
                                {project.rating}
                            </Typography> */}
                                </div>
                            </div>
                        </Box>
                    </Grid>
                )
            })}{' '}
        </Grid>
    )
}

export default WinnerDisplay
