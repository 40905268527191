import React from 'react'

export default ({ score, setScore, name }) => {
    // render 10 radio buttons

    return (
        <div className="mb-4">
            <div className="flex justify-between px-1">
                <div>0</div>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
                <div>6</div>
                <div>7</div>
                <div>8</div>
                <div>9</div>
                <div>10</div>
            </div>
            {/* <label
                htmlFor="steps-range"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
                Range steps
            </label> */}
            <input
                id="steps-range"
                type="range"
                min="0"
                max="10"
                value={score}
                onChange={e => {
                    console.log(e.target.value)
                    setScore(parseFloat(e.target.value))
                }}
                step="1"
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            />
        </div>
    )

    // return (
    //     <div className="mb-4">
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-1"
    //                 type="radio"
    //                 checked={score === 1}
    //                 name={name}
    //                 onChange={() => setScore(1)}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-1"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 1
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-2"
    //                 type="radio"
    //                 checked={score === 2}
    //                 onChange={() => setScore(2)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-2"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 2
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-3"
    //                 type="radio"
    //                 checked={score === 3}
    //                 onChange={() => setScore(3)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-3"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 3
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-4"
    //                 type="radio"
    //                 checked={score === 4}
    //                 onChange={() => setScore(4)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-4"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 4
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-5"
    //                 type="radio"
    //                 checked={score === 5}
    //                 onChange={() => setScore(5)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-5"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 5
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-6"
    //                 type="radio"
    //                 checked={score === 6}
    //                 onChange={() => setScore(6)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-6"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 6
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-7"
    //                 type="radio"
    //                 checked={score === 7}
    //                 onChange={() => setScore(7)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-7"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 7
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-8"
    //                 type="radio"
    //                 checked={score === 8}
    //                 onChange={() => setScore(8)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-8"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 8
    //             </label>
    //         </div>
    //         <div className="flex items-center mb-1">
    //             <input
    //                 id="default-radio-9"
    //                 type="radio"
    //                 checked={score === 9}
    //                 onChange={() => setScore(9)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-9"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 9
    //             </label>
    //         </div>
    //         <div className="flex items-center">
    //             <input
    //                 id="default-radio-10"
    //                 type="radio"
    //                 checked={score === 10}
    //                 onChange={() => setScore(10)}
    //                 name={name}
    //                 className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
    //             />
    //             <label
    //                 htmlFor="default-radio-10"
    //                 className="ml-2 text-sm font-medium text-gray-900 "
    //             >
    //                 10
    //             </label>
    //         </div>
    //     </div>
    // )
}
