import LogRocket from 'logrocket'

import * as ActionTypes from './actionTypes'
import * as AuthSelectors from 'redux/auth/selectors'
import config from 'constants/config'
import { getCookieConsentValue } from 'react-cookie-consent'
import UserProfilesService from 'services/userProfiles'
import Auth0Service from 'services/auth0'
import { logout, setSession } from 'redux/auth/actions'

export const setUserProfile = profile => async dispatch => {
    // console.log('setUserProfile\n----------------running')
    dispatch({
        type: ActionTypes.SET_PROFILE,
        payload: profile,
    })

    // try {
    //     // console.log('updateUserProfile\n----------------running')
    //     const authResult = await Auth0Service.checkSession()
    //     // console.log('authResult -------- updateUser profile\n', authResult)
    //     // dispatch(setSession(authResult))
    //     const userProfile = await UserProfilesService.getUserProfile(
    //         authResult.idToken,
    //     )
    //     dispatch({
    //         type: ActionTypes.SET_PROFILE,
    //         payload: {
    //             userProfile,
    //             // accessToken: authResult.accessToken,
    //             // idToken: authResult.idToken,
    //             // idTokenPayload: authResult.idTokenPayload,
    //             // expiresAt: authResult.expiresIn * 1000 + new Date().getTime(),
    //         },
    //     })
    //     // dispatch(updateUserProfile(authResult.idToken))
    // } catch (err) {
    //     console.log('error in renewSession in editUserProfile')
    //     // dispatch(logout())
    // }

    /** To connect logs and crash reports with the user */
    if (
        profile?.userId &&
        config.LOGROCKET_ID &&
        getCookieConsentValue() === 'true'
    ) {
        LogRocket.init(config.LOGROCKET_ID)
        LogRocket.identify(profile.userId, {
            email: profile.email,
            name: `${profile.firstName} ${profile.lastName}`,
        })
    }
}

export const updateUserProfile = idToken => async dispatch => {
    console.log('updateUserProfile\n----------------running')
    const userProfile = await UserProfilesService.getUserProfile(idToken)

    if (userProfile) {
        dispatch(setUserProfile(userProfile))
    } else {
        dispatch(setUserProfile(null))
    }

    return userProfile
}

export const editUserProfile = data => async (dispatch, getState) => {
    console.log('editUserProfile\n----------------running')
    let flag = 0
    if (data.role2) {
        data.role = data.role2
        delete data.role2
        flag = 1
    }
    const idToken = AuthSelectors.getIdToken(getState())

    const userProfile = await UserProfilesService.editUserProfile(idToken, data)

    if (data.role && !flag) {
        try {
            // console.log('updateUserProfile\n----------------running')
            const authResult = await Auth0Service.checkSession()
            console.log('authResult -------- updateUser profile\n', authResult)
            dispatch(setSession(authResult))
            // dispatch(updateUserProfile(authResult.idToken))
        } catch (err) {
            console.log('error in renewSession in editUserProfile', err)
            // dispatch(logout())
        }
    }

    dispatch(setUserProfile(userProfile))

    return userProfile
}

export const setRegistration = (registration, eventId) => dispatch => {
    // console.log('setRegistration\n----------------running')
    dispatch({
        type: ActionTypes.SET_REGISTRATION,
        payload: {
            registration,
            eventId,
        },
    })
}

// export const getUserProfile = () => (dispatch, getState) => {
//     const state = getState();
//     const idToken = AuthSelectors.getIdToken(state);

//     dispatch({
//         type: ActionTypes.UPDATE_PROFILE,
//         promise: UserProfilesService.getUserProfile(idToken),
//         meta: {
//             onFailure: e => console.log('Failed to get user profile', e)
//         }
//     });
// };

// export const updateUserProfile = data => (dispatch, getState) => {
//     const state = getState();
//     const idToken = AuthSelectors.getIdToken(state);

//     dispatch({
//         type: ActionTypes.UPDATE_PROFILE,
//         promise: UserProfilesService.updateUserProfile(data, idToken),
//         meta: {
//             onFailure: e => console.log('Failed to update user profile', e)
//         }
//     });
// };

// export const createUserProfile = data => (dispatch, getState) => {
//     const state = getState();
//     const idToken = AuthSelectors.getIdToken(state);

//     dispatch({
//         type: ActionTypes.UPDATE_PROFILE,
//         promise: UserProfilesService.createUserProfile(data, idToken),
//         meta: {
//             onFailure: e => console.log('Failed to create user profile', e)
//         }
//     });
// };
