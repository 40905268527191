const EventStatuses = require('../constants/event-statuses')
console.log('here');
const nowIsBefore = (time, moment) => {
    if (!time) {
        console.log('No time supplied to nowIsBefore')
    }

    const now = moment().utc()
    const timeMoment = moment(time).utc()

    return now.isBefore(timeMoment)
}
const nowIsAfter = (endTime, moment) => {
    if (!endTime) {
        console.log('No time supplied to nowIsBefore')
    }

    const now = moment().utc()
    const timeMoment = moment(endTime).utc()

    return now.isAfter(timeMoment)
}
const nowIsBetween = (startTime, endTime, moment) => {
    if (!startTime) {
        console.log('No startTime supplied to nowIsBetween')
        return false
    }
    if (!endTime) {
        console.log('No endTime supplied to nowIsBetween')
        return false
    }
    const now = moment().utc()
    const startMoment = moment(startTime).utc()
    const endMoment = moment(endTime).utc()

    return now.isBetween(startMoment, endMoment)
}

function concatAndCapitalize(variableName, location) {
    const locationalias = {
        EMEA: 'emca',
        Americas: '',
        India: 'asia',
    }

    // Capitalize the first letter of the firstString
    let variableNameCapitalized = variableName
    if (location !== 'Americas') {
        variableNameCapitalized =
            variableName.charAt(0).toUpperCase() + variableName.slice(1)
    }

    // Concatenate the secondString and capitalizedFirstString
    const result = locationalias[location] + variableNameCapitalized

    return result
}

const EventHelpers = {
    isRegistrationOpen: (event, moment, location) => {
        let registrationStartTimeAlias = 'registrationStartTime'
        let registrationEndTimeAlias = 'registrationEndTime'

        if (location) {
            registrationEndTimeAlias = concatAndCapitalize(
                registrationEndTimeAlias,
                location,
            )
            registrationStartTimeAlias = concatAndCapitalize(
                registrationStartTimeAlias,
                location,
            )
        }

        if (!event) return false
        return nowIsBetween(
            event[registrationStartTimeAlias],
            event[registrationEndTimeAlias],
            moment,
        )
    },
    isSubmissionsOpen: (event, moment, location) => {
        if (!event) return false
        let submissionsStartTimeAlias = 'submissionsStartTime'
        let submissionsEndTimeAlias = 'submissionsEndTime'

        if (location) {
            submissionsStartTimeAlias = concatAndCapitalize(
                submissionsStartTimeAlias,
                location,
            )
            submissionsEndTimeAlias = concatAndCapitalize(
                submissionsEndTimeAlias,
                location,
            )
        }
        return nowIsBetween(
            event[submissionsStartTimeAlias],
            event[submissionsEndTimeAlias],
            moment,
        )
    },
    isSubmissionsUpcoming: (event, moment, location) => {
        if (!event) return false

        let submissionsStartTimeAlias = 'submissionsStartTime'

        if (location) {
            submissionsStartTimeAlias = concatAndCapitalize(
                submissionsStartTimeAlias,
                location,
            )
        }
        return nowIsBefore(event[submissionsStartTimeAlias], moment)
    },
    isSubmissionsPast: (event, moment, location) => {
        if (!event) return false

        let submissionsEndTimeAlias = 'submissionsEndTime'
        if (location) {
            submissionsEndTimeAlias = concatAndCapitalize(
                submissionsEndTimeAlias,
                location,
            )
        }

        return !nowIsBefore(event[submissionsEndTimeAlias], moment)
    },
    isSubmissionsClosed: (event, moment, location) => {
        if (!event) return false

        let submissionsEndTimeAlias = 'submissionsEndTime'

        if (location) {
            submissionsEndTimeAlias = concatAndCapitalize(
                submissionsEndTimeAlias,
                location,
            )
        }
        return !nowIsAfter(event[submissionsEndTimeAlias], moment)
    },
    isVotingOpen: (event, moment, location) => {
        if (!event) return false

        let reviewingStartTimeAlias = 'reviewingStartTime'
        let reviewingEndTimeAlias = 'reviewingEndTime'

        if (location) {
            reviewingStartTimeAlias = concatAndCapitalize(
                reviewingStartTimeAlias,
                location,
            )
            reviewingEndTimeAlias = concatAndCapitalize(
                reviewingEndTimeAlias,
                location,
            )
        }

        return nowIsBetween(
            event[reviewingStartTimeAlias],
            event[reviewingEndTimeAlias],
            moment,
        )
    },
    isFinalistVotingOpen: (event, moment, location) => {
        if (!event) return false

        let reviewingEndTimeAlias = 'reviewingEndTime'
        let endTimeAlias = 'endTime'
        if (location) {
            reviewingEndTimeAlias = concatAndCapitalize(
                reviewingEndTimeAlias,
                location,
            )
            endTimeAlias = concatAndCapitalize(endTimeAlias, location)
        }
        
        console.log(nowIsBetween(
            event[reviewingEndTimeAlias],
            event[endTimeAlias],
            moment,
        ));
        return (
            nowIsBetween(
                event[reviewingEndTimeAlias],
                event[endTimeAlias],
                moment,
            )
        )
    },
    isVotingPast: (event, moment, location) => {
        if (!event) return true

        let reviewingEndTimeAlias = 'reviewingEndTime'

        if (location) {
            reviewingEndTimeAlias = concatAndCapitalize(
                reviewingEndTimeAlias,
                location,
            )
        }

        return !nowIsBefore(event[reviewingEndTimeAlias], moment)
    },
    isEventOngoing: (event, moment, location) => {
        if (!event) return false

        let startTimeAlias = 'startTime'
        let endTimeAlias = 'endTime'

        if (location) {
            startTimeAlias = concatAndCapitalize(startTimeAlias, location)
            endTimeAlias = concatAndCapitalize(endTimeAlias, location)
        }

        return nowIsBetween(event[startTimeAlias], event[endTimeAlias], moment)
    },
    isEventOver: (event, moment, location) => {
        if (!event) return false

        let endTimeAlias = 'endTime'

        if (location) {
            endTimeAlias = concatAndCapitalize(endTimeAlias, location)
        }
        return !nowIsBefore(event[endTimeAlias], moment)
    },
    isGrantDeadlinePast: (event, moment, location) => {
        if (!event) return true

        let endTimeAlias = 'endTime'

        if (location) {
            endTimeAlias = concatAndCapitalize(endTimeAlias, location)
        }

        return !nowIsBefore(
            moment(event[endTimeAlias]).add(7, 'days').format(),
            moment,
        )
    },
    getEventStatus: (event, moment, location) => {
        if (!event) return null

        let registrationStartTimeAlias = 'registrationStartTime'
        let registrationEndTimeAlias = 'registrationEndTime'
        let startTimeAlias = 'startTime'
        let endTimeAlias = 'endTime'

        if (location) {
            registrationStartTimeAlias = concatAndCapitalize(
                registrationStartTimeAlias,
                location,
            )
            registrationEndTimeAlias = concatAndCapitalize(
                registrationEndTimeAlias,
                location,
            )
            startTimeAlias = concatAndCapitalize(startTimeAlias, location)
            endTimeAlias = concatAndCapitalize(endTimeAlias, location)
        }

        const now = moment().utc()

        if (now.isBefore(event[registrationStartTimeAlias])) {
            return EventStatuses.PUBLISHED.id
        }
        if (now.isBefore(event[registrationEndTimeAlias])) {
            return EventStatuses.REGISTRATION_OPEN.id
        }
        if (now.isBefore(event[endTimeAlias])) {
            if (now.isBefore(event[startTimeAlias])) {
                const eventBegins = moment(event[startTimeAlias]).utc()
                if (eventBegins.diff(now, 'days') < 7) {
                    return EventStatuses.WEEK_OF_EVENT.id
                }
                return EventStatuses.REGISTRATION_ENDED.id
            }
            return EventStatuses.IN_PROGRESS.id
        }
        return EventStatuses.FINISHED.id
    },
}

module.exports = EventHelpers
